import {useMutation} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useCommon} from '../context/CommonContext';
import {RegisterUser} from '../shared/types/RegisterUser.type';
import {useState} from 'react';

export function useRegisterUser() {
    const appService = new AppService();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const return_url = searchParams.get('return_url');
    const {t} = useTranslation();
    const {setSnackbar} = useCommon();
    const [email, setEmail] = useState<string | undefined>(undefined);

    const {isPending, mutate} = useMutation({
        mutationKey: ['registerUser'],
        mutationFn: async (registerData: RegisterUser) => {
            setEmail(registerData.email);
            const res = await appService.api_registerUser('player', registerData);
            return res;
        },
        retry: false,
        onError: (error: any) => {
            setSnackbar({
                open: true,
                severity: 'error',
                children: t(`register.error.${error.response.status}`, {email: error.response.data.message}),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
            });
        },
        onSuccess: (data) => {
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'success',
                children: t('register.success'),
            });

            if (email) {
                navigate(`/verify?email=${email}${return_url ? `&return_url=${return_url}` : ''}`);
            }
        },
    });

    return {mutate, isPending};
}
