import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useBooking} from '../../hooks/useBooking';
import {StyledBox} from './Booking.styles';
import {BookingForm} from '../bookingForm/BookingForm';
import {BookingDetails} from '../bookingDetails/BookingDetails';
import dayjs from 'dayjs';
import {Layout} from '../../../../components/Common/Layout/Layout';

export const Booking = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const areaId = searchParams.get('area');
    const category = searchParams.get('sport');
    const categoryId = searchParams.get('sportId');
    const date = searchParams.get('date');
    const time = searchParams.get('time');
    const timeUnit = searchParams.get('timeUnit');
    const price = searchParams.get('price');

    const {
        isLoading,
        area,
        actions: {fetchArea},
    } = useBooking();

    useEffect(() => {
        const hasMissingField = [areaId, category, categoryId, date, time, timeUnit].some((val) => !!!val);
        if (hasMissingField) {
            navigate('/search');
            return;
        }

        fetchArea(areaId!);
    }, []);

    if (isLoading || !area) {
        return null;
    }

    const formattedDate = dayjs.unix(Number(date)).format('ddd, MMM D, YYYY');
    const from = dayjs.unix(Number(time)).utc().format('HH:mm');
    const [hoursToAdd, minutesToAdd] = dayjs.unix(Number(timeUnit)).utc().format('HH:mm').split(':');
    const to = dayjs
        .unix(Number(time))
        .utc()
        .add(Number(hoursToAdd), 'hour')
        .add(Number(minutesToAdd), 'minute')
        .format('HH:mm');

    return (
        <Layout headerType="menu" maxWidth="sm">
            <StyledBox>
                <BookingDetails
                    club={{
                        name: area.club.name,
                        mobilePhone: area.club.mobilePhone,
                        email: area.club.email,
                        carParkType: area.club.carParkType,
                        lockerRoomType: area.club.lockerRoomType,
                        address: {
                            address: area.club.address?.address,
                            postalCode: area.club.address?.postalCode,
                            district: area.club.address?.district,
                            lat: area.club.address?.lat,
                            lng: area.club.address?.lng,
                        },
                        photos: area.club.photos.map((photo: any) => ({
                            photoUrl: photo.photoUrl,
                        })),
                    }}
                    area={{
                        name: area.name,
                        description: area.description,
                        price: price ?? '0',
                        privacy: area.privacy,
                        environment: area.environment,
                        photos: area.photos.map((photo: any) => ({
                            photoUrl: photo.photoUrl,
                        })),
                    }}
                    category={category!}
                    date={formattedDate!}
                    from={from!}
                    to={to!}
                />
                <BookingForm
                    areaName={area.name!}
                    areaId={areaId!}
                    category={category!}
                    date={date!}
                    time={time!}
                    timeUnit={timeUnit!}
                    privacy={area.privacy!}
                    price={price!}
                    clubId={area.clubId!}
                />
            </StyledBox>
        </Layout>
    );
};
