import React, {FC, useEffect} from 'react';
import {Layout} from '../../../../components/Common/Layout/Layout';
import {CountdownTimer} from '../../../../components/CountdownTimer/CountdownTimer';
import {TimerContainer, TimerDescription, TimerBox} from './BookingForm.styles';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {useMbwayPaymentStatus} from '../../../../hooks/useMbwayPaymentStatus';
import {PaymentStatusType} from '../../../../shared/enums/PaymentStatusType.enum';
import {useCommon} from '../../../../context/CommonContext';

export const MBWayTimer: FC = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const mbwayPaymentData = location.state;
    const navigate = useNavigate();
    const {setSnackbar} = useCommon();

    const {
        isLoading,
        status,
        actions: {fetchPaymentStatus},
    } = useMbwayPaymentStatus();

    const handleOnComplete = () => {
        fetchPaymentStatus(mbwayPaymentData.reservationId);
    };

    const handleConfirmPayment = async () => {
        handleOnComplete();
    };

    useEffect(() => {
        if (status === PaymentStatusType.SUCCESS) {
            navigate(`/booking-details/${mbwayPaymentData.reservationId}`);
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'success',
                children: t('mbway.status.success'),
            });
        }

        if (status === PaymentStatusType.CANCELED || status === PaymentStatusType.ERROR) {
            navigate(-1);
            setSnackbar({
                open: true,
                title: t(`mbway.status.title.${status}`).toLowerCase(),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t(`mbway.status.${status}`).toLowerCase(),
            });
        }
    }, [status, navigate, mbwayPaymentData]);

    return (
        <Layout headerType="menu" maxWidth="sm">
            <TimerContainer>
                <TimerDescription>
                    {t('mbway.paymentConfirmationDetail', {mobilePhone: mbwayPaymentData.mobilePhone})}
                </TimerDescription>
                <TimerBox>
                    <CountdownTimer duration={240} onComplete={handleOnComplete} />
                    <TimerDescription>{t('mbway.paymentDescription')}</TimerDescription>
                </TimerBox>
                <AppButton variant="contained" onClick={handleConfirmPayment} disabled={isLoading}>
                    {isLoading ? t('mbway.confirmingPayment') : t('mbway.confirmPayment')}
                </AppButton>
            </TimerContainer>
        </Layout>
    );
};
