import React from 'react';
import {ErrorMessage} from 'formik';

import {useTranslation} from 'react-i18next';
import {InputLabel, MenuItem, Checkbox, ListItemText, List as ListIcon} from '@mui/material';
import {ClubArea} from '../../../../shared/types/ClubArea.type';
import {Category} from '../../../../shared/types/Category.type';
import {CalendarToday} from '@mui/icons-material';
import MUIDatePicker from '../../../../components/DatePicker/DatePicker';
import {StyledError} from '../../history/HistoryData.styles';
import {Select} from '../../../../components/Common/Select/Select';
import {
    ButtonView,
    FilterBox,
    FilterButton,
    SearchInput,
    SelectGrid,
    StyledDesktopFilter,
    StyledFormControl,
} from '../Filters.styles';
import {MUIDateRangePicker} from '../dashboard-filter/DashBoardFilters';

interface DesktopFilterProps {
    isFilterOpen: boolean;
    values: any;
    errors: any;
    touched: any;
    handleChange: (event: any) => void;
    areas: ClubArea[];
    sports: Category[] | undefined;
    isListView: boolean;
    setIsListView: (value: boolean) => void;
    setFieldValue: (field: string, value: any) => void;
}

const DesktopFilter: React.FC<DesktopFilterProps> = ({
    isFilterOpen,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    areas,
    sports,
    isListView,
    setIsListView,
}) => {
    const {t} = useTranslation();
    const isDashboard = window.location.pathname.includes('dashboard');

    return (
        <StyledDesktopFilter isFilterOpen={isFilterOpen}>
            <SelectGrid>
                <FilterBox>
                    <StyledFormControl variant="outlined" error={touched.selectedArea && Boolean(errors.selectedArea)}>
                        <InputLabel>{t('clubAdmin.bookings.selectArea')}</InputLabel>
                        <Select
                            name="selectedArea"
                            labelId="selectedArea"
                            value={values.selectedArea}
                            onChange={handleChange}
                        >
                            <MenuItem value="all">{t('clubAdmin.bookings.allAreas')}</MenuItem>
                            {areas?.map((area: ClubArea) => (
                                <MenuItem key={area.id} value={area.id}>
                                    {area?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                    <ErrorMessage name="selectedArea">{(msg: string) => <StyledError>{msg}</StyledError>}</ErrorMessage>
                </FilterBox>
                {isDashboard && (
                    <FilterBox>
                        <MUIDateRangePicker values={values} setFieldValue={setFieldValue} />
                    </FilterBox>
                )}
                {!isDashboard && (
                    <>
                        <FilterBox>
                            <StyledFormControl
                                variant="outlined"
                                error={touched.selectedSports && Boolean(errors.selectedSports)}
                            >
                                <InputLabel id="sports">{t('clubAdmin.bookings.selectCategory')}</InputLabel>
                                <Select
                                    name="selectedSports"
                                    labelId="selectedSports"
                                    value={values.selectedSports}
                                    multiple
                                    onChange={handleChange}
                                    renderValue={(selected: any) => selected.join(', ')}
                                >
                                    {sports?.map((sport: Category) => (
                                        <MenuItem key={sport} value={sport}>
                                            <Checkbox checked={values.selectedSports.indexOf(sport) > -1} />
                                            <ListItemText primary={sport} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledFormControl>
                            <ErrorMessage name="selectedSports">
                                {(msg: string) => <StyledError>{msg}</StyledError>}
                            </ErrorMessage>
                        </FilterBox>

                        {isListView && (
                            <>
                                <StyledFormControl>
                                    <MUIDatePicker
                                        label={t('clubAdmin.historyData.selectStartDate')}
                                        value={values.startDate}
                                        slotProps={{
                                            textField: {
                                                name: 'startDate',
                                                error: errors.startDate as boolean | undefined,
                                                helperText: errors.startDate as string,
                                            },
                                        }}
                                        onChange={(newValue) =>
                                            handleChange({target: {name: 'startDate', value: newValue}})
                                        }
                                    />
                                    <ErrorMessage name="startDate">
                                        {(msg: string) => <StyledError>{msg}</StyledError>}
                                    </ErrorMessage>
                                </StyledFormControl>
                                <StyledFormControl>
                                    <MUIDatePicker
                                        label={errors.endDate ? '' : t('clubAdmin.historyData.selectEndDate')}
                                        value={values.endDate}
                                        slotProps={{
                                            textField: {
                                                name: 'endDate',
                                                error: errors.endDate as boolean | undefined,
                                                helperText: errors.endDate as string,
                                            },
                                        }}
                                        onChange={(newValue) =>
                                            handleChange({target: {name: 'endDate', value: newValue}})
                                        }
                                    />
                                    <ErrorMessage name="endDate">
                                        {(msg: string) => <StyledError>{msg}</StyledError>}
                                    </ErrorMessage>
                                </StyledFormControl>
                            </>
                        )}

                        <FilterBox>
                            <SearchInput
                                name="search"
                                placeholder={t('clubAdmin.bookings.inputLabel.search')}
                                label={t('clubAdmin.bookings.inputLabel.search')}
                                type="text"
                                value={values.search}
                                onChange={handleChange}
                                error={touched.search && Boolean(errors.search)}
                                sx={{width: '100%'}}
                            />
                            <ErrorMessage name="search">
                                {(msg: string) => <StyledError>{msg}</StyledError>}
                            </ErrorMessage>
                        </FilterBox>
                        <FilterBox>
                            {isListView ? (
                                <ButtonView
                                    variant="outlined"
                                    startIcon={<CalendarToday />}
                                    onClick={() => setIsListView(false)}
                                    sx={{minWidth: '278px', minHeight: '56px'}}
                                >
                                    {t('clubAdmin.bookings.calendarButton')}
                                </ButtonView>
                            ) : (
                                <ButtonView
                                    variant="outlined"
                                    startIcon={<ListIcon />}
                                    onClick={() => setIsListView(true)}
                                    sx={{minWidth: '278px', minHeight: '56px'}}
                                >
                                    {t('clubAdmin.bookings.listButton')}
                                </ButtonView>
                            )}
                        </FilterBox>
                    </>
                )}
            </SelectGrid>
            <FilterButton isFilterOpen={isFilterOpen} variant="contained" type="submit">
                {t('filter')}
            </FilterButton>
        </StyledDesktopFilter>
    );
};

export default DesktopFilter;
