import {useMutation} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {Photo} from '../models/photo.model';

export function useReservationPaymentProof(reservationId: string) {
    const appService = new AppService();

    const {data, mutate} = useMutation({
        mutationKey: ['reservationPaymentProof'],
        mutationFn: async (paymentProof: Photo[]) => {
            const res = await appService.api_registerPaymentProof(reservationId, paymentProof);
            return res.data;
        },
    });

    return {data, mutate};
}
