import {useMutation} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {RequestAccount} from '../shared/types/RequestAccount.type';

export function useRequestAccount() {
    const appService = new AppService();

    const {mutate, isError} = useMutation({
        mutationKey: ['requestAccount'],
        mutationFn: async (userData: RequestAccount) => {
            const res = await appService.api_requestClubAdminAccount(userData);
            return res;
        },
        retry: false,
    });

    return {mutate, isError};
}
