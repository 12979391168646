import {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Box, Button} from '@mui/material';
import {Input} from '../../components/Common/Input/TextField/Input';
import {Layout} from '../../components/Common/Layout/Layout';
import {GoBackButton} from '../../components/Common/GoBackButton';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getURLWithReturn} from '../../utils/returnUrl';
import {StyledBackgroundImage, Image, Container, Header, InputContainer} from './VerifyAccount.styles';
import {useVerifyAccount} from '../../hooks/useVerifyAccount';
import {VerifyAccount} from '../../shared/types/VerifyAccount.type';

const VerifyAccount = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [code, setCode] = useState('');
    const email = searchParams.get('email') || '';
    const return_url = searchParams.get('return_url');
    const {mutate} = useVerifyAccount();

    //Translation
    const {t} = useTranslation();

    useEffect(() => {
        if (email === '') navigate(getURLWithReturn('/login', return_url));
    }, [email, navigate]);

    const handleSubmit = async () => {
        if (code === '') return;
        const verifyData: VerifyAccount = {
            email: email,
            code: code,
        };
        mutate(verifyData);
    };

    return (
        <>
            <Layout maxWidth="sm">
                <Container>
                    <Header>
                        <GoBackButton to="/login" />
                    </Header>
                    <Image src="/logo_aveiro_tech_city_white.png" alt="FreeCourts: Courts for everyone" />

                    <InputContainer component="section">
                        <Input
                            type="number"
                            placeholder={t('verify.verificationCode')}
                            value={code}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
                        />
                        <Button variant="contained" onClick={handleSubmit}>
                            {t('verify.okButton')}
                        </Button>
                    </InputContainer>
                </Container>
            </Layout>
            <StyledBackgroundImage src="/images/basketball.jpg" alt="Basketball background image" />
        </>
    );
};

export default VerifyAccount;
