import styled from 'styled-components';
import {BackgroundImage} from '../../components/Common/BackgroundImage/BackgroundImage';
import Box from '@mui/material/Box';

export const StyledBackgroundImage = styled(BackgroundImage)`
    filter: brightness(0.4);
`;

export const Image = styled.img`
    margin: 24px auto;
    height: 82px;
    width: fit-content;
`;

export const Container = styled.div`
    width: 100%;
    padding: 1rem 0 5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.nav`
    display: flex;
`;

export const InputContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: auto;
`;
