import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import FullCalendar from '@fullcalendar/react';
import {EventClickArg, EventInput} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptLocale from '@fullcalendar/core/locales/pt';
import enLocale from '@fullcalendar/core/locales/en-gb';
import {EventImpl} from '@fullcalendar/core/internal';
import {useTranslation} from 'react-i18next';
import {BookingModal} from '../BookingModal/BookingModal';
import {timeStringToMinutes} from '../../utils/dates';
import {generateOpeningHours} from '../../utils/filters';
import {ClubArea} from '../../shared/types/ClubArea.type';
import {useCommon} from '../../context/CommonContext';
import {AppContext} from '../../context/AppContext';
import ReservationCategoryIcon from '../../pages/club-admin/bookings/components/ReservationCategoryIcon';
import {StyledBox, CalendarContainer} from './BookingCalendar.styles';

dayjs.extend(utc);
dayjs.extend(timezone);

export type BookingCalendarProps = {
    bookings: any[];
    currDate: string;
    options?: {
        slotInterval?: string;
    };
    selectedAreaInfo?: ClubArea;
    onNewBooking: (start: Dayjs, end: Dayjs) => void;
    onBookingDelete: (id: string) => void;
    onDateChange: (newDate: string) => void;
};

export const BookingCalendar = ({
    bookings,
    currDate,
    options = {
        slotInterval: '00:30',
    },
    selectedAreaInfo,
    onNewBooking,
    onBookingDelete,
    onDateChange,
}: BookingCalendarProps) => {
    const {i18n, t} = useTranslation();
    const calendarRef = useRef(null);
    //AppContext
    const appContext = useContext(AppContext);
    const appClub = appContext.club;

    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [bookingModalOpen, setBookingModalOpen] = useState(false);
    const [newDate, setNewDate] = useState('');
    const {setSnackbar} = useCommon();

    const getCalendarApi = () => {
        if (!calendarRef.current) return null;
        const current = calendarRef.current as any;
        return current.getApi();
    };

    const calendarDate = useMemo(() => {
        const calendarAPI = getCalendarApi();
        if (calendarAPI) calendarAPI.gotoDate(currDate);
        return currDate;
    }, [currDate]);

    useEffect(() => {
        const calendarAPI = getCalendarApi();
        if (!calendarAPI) return;

        calendarAPI.getEvents().forEach((elem: EventImpl) => {
            elem.remove();
        });

        const colors: string[] = ['#2196F3', '#4CAF50', '#673AB7', '#FF9800', '#2196F3'];
        const areas: string[] = [];

        const events = Array.isArray(bookings)
            ? bookings.map((booking) => {
                  if (!areas.includes(booking.area?.id)) areas.push(booking.area?.id);

                  const bookingFrom = dayjs(booking.from);
                  const bookingTo = dayjs(booking.to);
                  const start = dayjs(booking.day)
                      .set('hour', bookingFrom.hour())
                      .set('minute', bookingFrom.minute())
                      .toISOString();
                  const end = dayjs(booking.day)
                      .set('hour', bookingTo.hour())
                      .set('minute', bookingTo.minute())
                      .toISOString();

                  const isCanceled = booking.canceled;

                  return {
                      id: booking.id,
                      groupId: booking.area?.id,
                      title: isCanceled ? `${booking.name} (${t('canceled')})` : booking.name,
                      start,
                      end,
                      display: 'block',
                      overlap: isCanceled,
                      color: isCanceled ? '#757575' : colors[areas.indexOf(booking.area?.id) % colors.length],
                      extendedProps: booking,
                  } as EventInput;
              })
            : [];

        calendarAPI.addEventSource(events);
    }, [bookings]);

    useEffect(() => {
        const calendarAPI = getCalendarApi();
        if (!calendarAPI) return;

        const newDate = dayjs(calendarAPI.getDate());

        onDateChange(newDate.format('YYYY-MM-DD'));
    }, [newDate]);

    const handleEventClick = (clickInfo: EventClickArg) => {
        setSelectedEvent(clickInfo.event);
        setBookingModalOpen(true);
    };

    const handleIsSelectAllowed = (selectionInfo: any) => {
        const start = dayjs(selectionInfo.start);
        const end = dayjs(selectionInfo.end);
        const correctMinutes = timeStringToMinutes(options.slotInterval as string);
        const selectMinutes = end.diff(start, 'minutes');
        return selectMinutes % correctMinutes === 0;
    };

    const isSelectionOverlapping = (selectionInfo: any) => {
        const calendarAPI = getCalendarApi();
        if (!calendarAPI) return;

        let isOverlapping = false;

        calendarAPI.getEvents().forEach((elem: EventImpl) => {
            if (
                !elem.overlap &&
                dayjs(selectionInfo.start).isBefore(dayjs(elem.extendedProps.from)) &&
                dayjs(selectionInfo.end).isAfter(dayjs(elem.extendedProps.to))
            )
                isOverlapping = true;
        });

        return isOverlapping;
    };

    const handleDateSelect = (selectionInfo: any): boolean => {
        const calendarAPI = getCalendarApi();
        if (!calendarAPI) return false;

        const start =
            calendarAPI.currentData.currentViewType !== 'timeGridDay'
                ? dayjs(selectionInfo.start).format('YYYY-MM-DD')
                : dayjs(selectionInfo.start).format('YYYY-MM-DDTHH:mm');
        const current =
            calendarAPI.currentData.currentViewType !== 'timeGridDay'
                ? dayjs().format('YYYY-MM-DD')
                : dayjs().format('YYYY-MM-DDTHH:mm');

        const areaSchedules = generateOpeningHours(
            selectedAreaInfo?.AreaSchedule,
            dayjs(selectionInfo?.start).locale('en').format('dddd').toUpperCase()
        );

        const areaScheduleStart =
            calendarAPI.currentData.currentViewType === 'timeGridDay'
                ? dayjs(selectionInfo.start).format('HH') < areaSchedules.from
                : false;
        const areaScheduleEnd =
            calendarAPI.currentData.currentViewType === 'timeGridDay'
                ? dayjs(selectionInfo.end).format('HH') > areaSchedules.to
                : false;

        if (areaScheduleStart || areaScheduleEnd) {
            calendarAPI.unselect();
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('clubAdmin.bookings.error.scheduleError'),
            });

            return true;
        }

        if (start < current) {
            calendarAPI.unselect();
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('clubAdmin.bookings.error.pastDate'),
            });
            return true;
        }

        if (appClub === null) {
            calendarAPI.unselect();
            setSnackbar({
                open: true,
                title: t('clubAdmin.bookings.error.noSelectedClubTitle'),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('clubAdmin.bookings.error.noSelectedClub'),
            });
            return true;
        }

        if (isSelectionOverlapping(selectionInfo)) {
            calendarAPI.unselect();

            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('clubAdmin.bookings.error.overlappingEvents'),
            });
            return true;
        }

        if (!handleIsSelectAllowed(selectionInfo)) {
            calendarAPI.unselect();
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('clubAdmin.bookings.error.invalidInterval').replace(
                    '{{time}}',
                    `${options.slotInterval as string}h`
                ),
            });
            return true;
        }

        onNewBooking(dayjs(selectionInfo.start).utc(), dayjs(selectionInfo.end).utc());
        return false;
    };

    const handleDeleteEvent = () => {
        onBookingDelete(selectedEvent.id);
        setSelectedEvent(null);
        appContext.setBookingUpdate(true);
    };

    return (
        <CalendarContainer>
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: '',
                    center: 'prev title next',
                    right: 'dayGridMonth,dayGridWeek,timeGridDay',
                }}
                timeZone="UTC"
                locales={[ptLocale, enLocale]}
                locale={i18n.language}
                slotLabelFormat={{hour: '2-digit', minute: '2-digit'}}
                slotLabelInterval={'01:00'}
                slotDuration={'00:30'}
                height={'90vh'}
                scrollTime={'09:00'}
                allDaySlot={false}
                selectable={true}
                selectMirror={true}
                // selectOverlap={false}
                initialView="dayGridMonth"
                initialDate={calendarDate}
                dayMaxEvents={true}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                select={(selectionInfo) => {
                    handleDateSelect(selectionInfo);
                }}
                datesSet={(info) => setNewDate(info.start.toISOString())}
                now={dayjs().format('YYYY-MM-DD')}
                businessHours={[
                    {
                        daysOfWeek: [1, 2, 3, 4, 5], // Monday - Friday
                        startTime: '00:00',
                        endTime: '23:00',
                    },
                ]}
                selectAllow={(selectionInfo) => handleDateSelect(selectionInfo)}
            />

            {selectedEvent && (
                <BookingModal
                    booking={selectedEvent.extendedProps}
                    open={bookingModalOpen}
                    onClose={() => setBookingModalOpen(false)}
                    onDelete={handleDeleteEvent}
                />
            )}
        </CalendarContainer>
    );
};

function renderEventContent(eventInfo: any) {
    return (
        <StyledBox>
            <div>
                <b>{eventInfo.timeText}</b>&nbsp;{eventInfo.event.title}
            </div>
            <ReservationCategoryIcon reservationType={eventInfo.event.extendedProps.reservationType} />
        </StyledBox>
    );
}
