import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Form, Formik} from 'formik';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useTranslation} from 'react-i18next';
import {Layout} from '../../../../components/Common/Layout/Layout';
import {checkArrayLengthById, getAreaCategories, getSelectedAreaInfo} from '../../../../utils/filters';
import {Stack, Fab} from '@mui/material';
import {Add as AddIcon} from '@mui/icons-material';
import {BookingList} from '../../../../components/BookingList';
import {AppContext} from '../../../../context/AppContext';
import {useSearchParams} from 'react-router-dom';
import {CalendarNewBookingModal} from '../../../../components/CalendarNewBookingModal';
import {FeatureTogglesContext} from '../../../../context/FeatureTogglesContext';
import {BookingCalendar} from '../../../../components/BookingCalendar/BookingCalendar';
import {validationBookingsSearchSchema} from '../../../../models/validationBookingsSearchSchema.model';
import {SelectedClubFilter} from '../../filters/selected-club-filter/SelectedClubFilter';
import {MobileFilter} from '../../filters/mobile-filter/MobileFilter';
import DesktopFilter from '../../filters/desktop-filter/DesktopFilter';
import {useClubBookings} from '../../../../hooks/useClubBookings';
import {useClubHistory} from '../../../../hooks/useClubHistory';
import {FeatureToggles} from '../../../../shared/enums/FeatureToggles.enum';

dayjs.extend(utc);

const BookingsSearch = () => {
    const {t} = useTranslation();
    const appContext = useContext(AppContext);
    const club = appContext.club;
    const featureToggles = useContext(FeatureTogglesContext);
    const clubFeatureToggle = featureToggles.featureToggles;
    const [searchParams, setSearchParams] = useSearchParams();
    const currDate = useMemo(() => {
        return searchParams.get('date')
            ? dayjs(searchParams.get('date')).format('YYYY-MM-DD')
            : dayjs().startOf('month').format('YYYY-MM-DD');
    }, [searchParams]);

    const firstDayOfMonth = dayjs(currDate).startOf('month').toISOString();
    const lastDayOfMonth = dayjs(currDate).endOf('month').toISOString();
    const [initialValues, setInitialValues] = useState<any>({
        selectedArea: 'all',
        selectedSports: [],
        startDate: dayjs(firstDayOfMonth),
        endDate: dayjs(lastDayOfMonth),
        search: '',
        areaOptions: {
            slotInterval: '00:30',
        },
        newBookingDate: {
            start: dayjs().utc(),
            end: dayjs().utc(),
        },
    });

    useEffect(() => {
        setInitialValues({
            ...initialValues,
            selectedArea: 'all',
        });
    }, [club]);

    const [isListView, setIsListView] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [newBookingModalOpen, setNewBookingModalOpen] = useState<boolean>(false);
    const [newBookingDate, setNewBookingDate] = useState(initialValues.newBookingDate);
    const fetchHistory =
        club &&
        club?.id !== undefined &&
        checkArrayLengthById(clubFeatureToggle, club?.id, FeatureToggles.RESERVATION_HISTORY);
    const {data: bookings} = useClubBookings('private', club?.id, fetchHistory === false, {
        startDate: isListView ? `${dayjs(initialValues.startDate).format('YYYY-MM-DD')}T00:00.000Z` : firstDayOfMonth,
        endDate: isListView ? `${dayjs(initialValues.endDate).format('YYYY-MM-DD')}T22:59.999Z` : lastDayOfMonth,
        areaId: initialValues.selectedArea !== 'all' ? initialValues.selectedArea : '',
        sports: initialValues.selectedSports,
        search: initialValues.search,
    });
    const {data: history} = useClubHistory('private', club?.id, fetchHistory === true, {
        startDate: isListView ? `${dayjs(initialValues.startDate).format('YYYY-MM-DD')}T00:00.000Z` : firstDayOfMonth,
        endDate: isListView ? `${dayjs(initialValues.endDate).format('YYYY-MM-DD')}T22:59.999Z` : lastDayOfMonth,
        areaId: initialValues.selectedArea !== 'all' ? initialValues.selectedArea : '',
        sports: initialValues.selectedSports,
        search: initialValues.search,
    });

    const handleBookingListDelete = (id: string) => {
        return bookings.id === id ? {...bookings, canceled: true} : bookings;
    };

    const handleIsPaidChange = (id: string, isPaid: boolean) => {
        return bookings.id === id ? {...bookings, isPayed: isPaid} : bookings;
    };

    const handleCalendarChangeDate = (newDate: string | undefined | null) => {
        if (!newDate) return;

        setSearchParams({date: newDate});
    };

    const handleNewBooking = (start: Dayjs, end: Dayjs) => {
        setNewBookingDate({
            start,
            end,
        });
        setNewBookingModalOpen(true);
    };

    return (
        <Layout headerType="menu" hideClubIcon={true} maxWidth={false}>
            <Stack alignItems="center" pb={1} spacing={2} mb={3}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationBookingsSearchSchema(t)}
                    onSubmit={async (values: any) => {
                        setInitialValues(values);
                    }}
                >
                    {(props) => (
                        <>
                            <SelectedClubFilter />
                            <Form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                                <MobileFilter
                                    values={props.values}
                                    t={t}
                                    isFilterOpen={isFilterOpen}
                                    setIsFilterOpen={setIsFilterOpen}
                                    areas={club ? club.areas : []}
                                />

                                <DesktopFilter
                                    isFilterOpen={isFilterOpen}
                                    values={props.values}
                                    errors={props.errors}
                                    touched={props.touched}
                                    handleChange={props.handleChange}
                                    areas={club ? club.areas : []}
                                    sports={club ? club.categories : []}
                                    isListView={isListView}
                                    setIsListView={setIsListView}
                                    setFieldValue={props.setFieldValue}
                                />
                                {isListView ? (
                                    <BookingList
                                        bookings={fetchHistory ? history : bookings}
                                        clubId={props.values.selectedClub!}
                                        onBookingDelete={handleBookingListDelete}
                                        onIsPaidUpdate={handleIsPaidChange}
                                        history={clubFeatureToggle[club?.id!]?.length >= 1}
                                    />
                                ) : (
                                    <BookingCalendar
                                        bookings={fetchHistory ? history : bookings}
                                        currDate={currDate}
                                        options={initialValues.areaOptions}
                                        onNewBooking={handleNewBooking}
                                        onBookingDelete={handleBookingListDelete}
                                        onDateChange={handleCalendarChangeDate}
                                        selectedAreaInfo={getSelectedAreaInfo(club?.areas, props.values.selectedArea)}
                                    />
                                )}
                            </Form>
                            <CalendarNewBookingModal
                                open={newBookingModalOpen}
                                onClose={() => setNewBookingModalOpen(false)}
                                club={club!}
                                selectedDay={newBookingDate}
                                selectedArea={props.values.selectedArea}
                                selectedSport={props.values.selectedSports}
                                areaSports={getAreaCategories(club?.areas, props.values.selectedArea)}
                                selectedAreaInfo={getSelectedAreaInfo(club?.areas, props.values.selectedArea)}
                            />
                        </>
                    )}
                </Formik>
            </Stack>
            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                sx={{
                    display: isListView ? 'flex' : 'none',
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                }}
                onClick={() => setIsListView(false)}
            >
                <AddIcon sx={{mr: 1}} />
                {t('clubAdmin.bookings.newBooking')}
            </Fab>
        </Layout>
    );
};

export default BookingsSearch;
