import {AccordionSummary, Typography, AccordionDetails, AccordionProps} from '@mui/material';
import React, {FC, PropsWithChildren} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {StyledAccordion} from './Accordion.styles';

export type AccordionComponentProps = {
    title: string;
} & AccordionProps;

export const AccordionComponent: FC<PropsWithChildren<AccordionComponentProps>> = ({title, expanded, children}) => {
    return (
        <StyledAccordion data-testid="accordion" expanded={expanded} onChange={(expanded) => !expanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </StyledAccordion>
    );
};
