import {useQuery} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {useEffect, useState} from 'react';
import {Category} from '../shared/types/Category.type';
import {ClubArea} from '../shared/types/ClubArea.type';

export function useClubById(role: string, clubId: string) {
    const appService = new AppService();
    const [areas, setAreas] = useState<ClubArea[]>([]);
    const [sports, setSports] = useState<Category[]>([]);

    const {isLoading, data = []} = useQuery({
        queryKey: ['clubById', clubId],
        queryFn: async () => {
            const res = await appService.api_getClubById(role, clubId);
            return res.data;
        },
        retry: false,
    });

    useEffect(() => {
        if (data) {
            setAreas(data.areas);
            setSports(data.categories);
        }
    }, [data]);

    return {data, isLoading, areas, sports};
}
