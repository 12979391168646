import styled, {css} from 'styled-components';
import {Input} from '../../../../components/Common/Input/TextField/Input';
import {Box, FormLabel} from '@mui/material';

export const StyledInput = styled(Input)`
    margin-bottom: 2rem !important;
`;

export const StyledFormLabel = styled(FormLabel)`
    color: #d32f2f !important;
    font-size: small !important;
    margin-left: 32px;
`;

export const Asterisk = styled.span`
    color: #d32f2f;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const CardDetailsBox = styled(Box)`
    display: grid;
    grid-template-columns: 50% auto;
    gap: 8px;
`;

export const Label = styled.div`
    color: var(--custom-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
`;

const commonStyles = css`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const CardForm = styled.form`
    ${commonStyles};
`;

export const InputBox = styled.div`
    ${commonStyles};
`;

export const PaymentMethodContainer = styled.div`
    margin: 0;
    padding: 0;
`;

export const CreditCardBox = styled(Box)`
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
`;

export const PhoneBox = styled(Box)`
    display: grid;
    grid-template-columns: 10% auto;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: var(--black-026);

    @media (max-width: 600px) {
        grid-template-columns: 15% auto;
    },
`;

export const CountryPhoneCode = styled(Box)`
    padding: 8px 12px;
    background: var(--black-012);
    height: 56px;
    display: flex;
    align-items: center;
    border-radius: 4px;
`;

export const TimerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const TimerDescription = styled.p`
    padding: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: 0;
`;

export const TimerBox = styled(Box)`
    background-color: #fff;
    display: flex;
    padding: 8px;
    gaop: 8px;
    align-items: center;
`;
