import React, {createContext, useState} from 'react';

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [role, setRole] = useState(localStorage.getItem('role'));

    const isAuthenticated = token !== null && token !== '';

    const refresh = () => {
        setToken(localStorage.getItem('token'));
        setRole(localStorage.getItem('role'));
    };

    const logout = () => {
        localStorage.removeItem('token');
        setToken(null);
        localStorage.removeItem('role');
        setRole(null);
    };

    const login = (token, role) => {
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        refresh();
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                isAuthenticated,
                role,
                refresh,
                logout,
                login,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export {AuthContext, AuthProvider};
