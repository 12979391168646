import React, {FC} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StyledTimePicker} from './TimePicker.styles';
import {TimePickerProps} from '@mui/x-date-pickers';
import {Dayjs} from 'dayjs';

const MUITimePicker: FC<TimePickerProps<Dayjs>> = ({...props}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledTimePicker
                format="HH:mm"
                ampm={false}
                disableIgnoringDatePartForTimeValidation={true}
                timeSteps={{hours: 1, minutes: 15}}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default MUITimePicker;
