import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import type {ChartOptions} from 'chart.js';
import {weekDaysMapping} from '../../../../utils/week-days';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const reservationByAreaOptions: ChartOptions<'pie'> = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
        legend: {
            position: 'right',
            align: 'center',
            labels: {
                textAlign: 'left',
                boxHeight: 8,
                boxWidth: 8,
            },
        },
        title: {
            display: false,
        },
        // datalabels: {
        //     display: false,
        // },
    },
};

export const reservationBySportOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    plugins: {
        legend: {
            position: 'right',
            align: 'center',
            labels: {
                textAlign: 'left',
                boxHeight: 8,
                boxWidth: 8,
            },
        },
        title: {
            display: false,
        },
        // datalabels: {
        //     display: false,
        // },
    },
};

export const getMaxValue = (data: any[]): number => {
    let maxValue = 0;
    for (const item of data) {
        if (item.count > maxValue) {
            maxValue = item.count;
        }
    }
    return maxValue;
};

export const reservationByDaysOptions = (data: any): ChartOptions<'bar'> => {
    const maxValue = getMaxValue(data) + 1;
    return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            // datalabels: {
            //     display: true,
            //     anchor: 'center',
            //     align: 'center',
            // },
            tooltip: {
                callbacks: {
                    title: function (context) {
                        const index = context[0].dataIndex;
                        return weekDaysMapping[index];
                    },
                    label: function (context) {
                        return `Reservas: ${context.raw}`;
                    },
                },
            },
        },
        scales: {
            y: {
                stacked: true,
                position: 'left',
                beginAtZero: true,
                grid: {
                    display: false,
                    color: 'rgba(255, 255, 255, 1)',
                },
                ticks: {
                    font: {
                        weight: 'bold',
                    },
                },
            },
            x: {
                max: maxValue,
                display: false,
            },
        },
    };
};

export const reservationByTimeDayOptions = (data: any): ChartOptions<'line'> => {
    const maxValue = calculateMaxValue(data);
    const stepSize = calculateStepSize(maxValue);
    return {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'center',
                labels: {
                    textAlign: 'left',
                    boxHeight: 8,
                    boxWidth: 8,
                },
            },
            title: {
                display: false,
            },
            // datalabels: {
            //     display: true,
            // },
        },
        scales: {
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    stepSize: stepSize,
                },
                max: maxValue,
            },
        },
    };
};

function calculateMaxValue(data: any): number {
    let total: number = 0;
    let returnValue = (value: number) => value + 1;
    for (const row in data) {
        total += data[row].reduce((acc: number, value: number) => acc + value, 0);
    }
    return returnValue(total);
}

function calculateStepSize(maxValue: number): number {
    return Math.ceil(maxValue / 10);
}

export const reservationByTypeOptions = (data: any): ChartOptions<'bar'> => {
    const maxValue = calculateMaxValue(data);
    const stepSize = calculateStepSize(maxValue);

    return {
        plugins: {
            legend: {
                position: 'bottom',
                align: 'center',
                labels: {
                    textAlign: 'left',
                    boxHeight: 8,
                    boxWidth: 8,
                },
            },
            title: {
                display: false,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    stepSize: stepSize,
                },
                max: maxValue,
            },
        },
    };
};
