import {AppService} from '../services/app.service';
import {useState} from 'react';
import {PaymentStatusType} from '../shared/enums/PaymentStatusType.enum';

export const useMbwayPaymentStatus = () => {
    const appService = new AppService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [status, setStatus] = useState<PaymentStatusType | null>(null);

    const fetchPaymentStatus = (id: string) => {
        setIsLoading(true);

        appService
            .api_getMbwayPaymentStatus(id)
            .then((res) => {
                if (res.status !== 200) throw new Error();

                if (res.data.status) {
                    setStatus(res.data.status);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setIsLoading(false));
    };

    return {
        isLoading,
        status,
        actions: {fetchPaymentStatus},
    };
};
