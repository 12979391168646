import {useMutation} from '@tanstack/react-query';
import {AppService} from '../services/app.service';

export function useCreateReservation(role: string) {
    const appService = new AppService();

    const {data, mutate, isError, isPending, error} = useMutation({
        mutationKey: ['createReservation'],
        mutationFn: async (reservation: any) => {
            const res = await appService.api_createBooking(role, reservation);
            return res;
        },
    });

    return {data, mutate, isError, isPending, error};
}
