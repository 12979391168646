import React, {ReactNode} from 'react';
import {Alert, AlertColor, AlertPropsVariantOverrides, AlertTitle, Snackbar} from '@mui/material';
import {OverridableStringUnion} from '@mui/types';

export type SnackbarAlertProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title?: string;
    children: ReactNode;
    vertical?: 'top' | 'bottom';
    horizontal?: 'center' | 'left' | 'right';
    variant?: OverridableStringUnion<'standard' | 'filled' | 'outlined', AlertPropsVariantOverrides>;
    severity?: AlertColor | undefined;
};

export const SnackbarAlert = ({
    open,
    setOpen,
    children,
    vertical = 'top',
    horizontal = 'center',
    variant = 'filled',
    severity,
    title,
}: SnackbarAlertProps) => {
    return (
        <Snackbar
            anchorOrigin={{vertical: vertical, horizontal: horizontal}}
            open={open}
            onClose={() => setOpen(false)}
        >
            <Alert
                onClose={() => setOpen(false)}
                variant={variant}
                severity={severity}
                sx={{width: '100%', maxWidth: '520px'}}
            >
                {title && <AlertTitle>{title}</AlertTitle>}

                {children}
            </Alert>
        </Snackbar>
    );
};
