import {Box} from '@mui/material';
import styled from 'styled-components';
import {Input} from '../../../../components/Common/Input/TextField/Input';

export const SelectGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    margin-top: 15px;
`;

export const DateRangePickerBox = styled(Box)`
    width: 100%;
    min-width: 278px;
`;

export const DateRangePickerCalendar = styled(Box)`
    position: absolute;
    margin-top: 5px;
`;

export const StyledInput = styled(Input)`
    width: 278px;
`;
