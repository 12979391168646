import React from 'react';
import {DialogActions, DialogContent, Stack, Typography} from '@mui/material';
import {AppButton} from '../Common/Buttons/AppButton';
import {useTranslation} from 'react-i18next';
import {useCommon} from '../../context/CommonContext';
import {useDeleteArea} from '../../hooks/useDeleteArea';
interface AreaDeleteFormProps {
    area: {
        id: string;
        name: string;
    };
    onDelete?: () => void | undefined;
}
export const AreaDeleteForm = ({area}: AreaDeleteFormProps) => {
    const {t} = useTranslation();
    const {setModal} = useCommon();
    const {mutate} = useDeleteArea();
    const handleCloseModal = () => {
        setModal({open: false, children: <></>});
    };
    const handleDeleteArea = () => {
        mutate(area.id);
    };
    return (
        <Stack spacing={1}>
            <DialogContent dividers>
                <Typography variant="body1" px={1}>
                    {t('areaDeleteDescription')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <AppButton
                    id="delete-area"
                    variant="contained"
                    color="error"
                    onClick={handleDeleteArea}
                    sx={{width: '100%'}}
                >
                    {t('delete')}
                </AppButton>
                <AppButton id="cancel" variant="outlined" type="reset" onClick={handleCloseModal} sx={{width: '100%'}}>
                    {t('cancel')}
                </AppButton>
            </DialogActions>
        </Stack>
    );
};
