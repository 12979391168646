import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {GoBackButton} from '../../../components/Common/GoBackButton';
import {Layout} from '../../../components/Common/Layout/Layout';
import {useTranslation} from 'react-i18next';
import {ErrorMessage, Formik} from 'formik';
import {AppButton} from '../../../components/Common/Buttons/AppButton';
import {Input} from '../../../components/Common/Input/TextField/Input';
import {getURLWithReturn} from '../../../utils/returnUrl';
import {validationRegisterSchema} from '../../../models/validationRegisterSchema.model';
import {Form, FormBox, GoBackBox, Image, StyledBackgroundImage} from './Register.styles';
import {RegisterUser} from '../../../shared/types/RegisterUser.type';
import {useRegisterUser} from '../../../hooks/useRegisterUser';

const Register = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();

    const return_url = searchParams.get('return_url');
    const {mutate, isPending} = useRegisterUser();

    const initialValues: RegisterUser = {
        name: '',
        email: '',
        password: '',
        phone: '',
        nif: '',
        address: '',
    };

    return (
        <>
            <Layout maxWidth="sm">
                <FormBox>
                    <GoBackBox component="nav">
                        <GoBackButton to={getURLWithReturn('/login', return_url)} />
                    </GoBackBox>
                    <Image src="/logo_aveiro_tech_city_white.png" alt="FreeCourts: Courts for everyone" />
                    <Typography color="common.white" variant="h5" textAlign="center">
                        {t('register.title')}
                    </Typography>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationRegisterSchema(t)}
                        onSubmit={async (values: RegisterUser) => {
                            mutate(values);
                        }}
                    >
                        {(props) => (
                            <Form onSubmit={props.handleSubmit}>
                                <Box>
                                    <Input
                                        name="name"
                                        placeholder={t('register.name.label')}
                                        type="text"
                                        autoComplete="off"
                                        value={props.values.name}
                                        onChange={props.handleChange}
                                        error={props.touched.name && !!props.errors.name}
                                    />
                                    <ErrorMessage name="name">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="email"
                                        placeholder={t('register.email.label')}
                                        type="text"
                                        autoComplete="off"
                                        value={props.values.email}
                                        onChange={props.handleChange}
                                        error={props.touched.email && !!props.errors.email}
                                    />
                                    <ErrorMessage name="email">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="password"
                                        placeholder={t('password')}
                                        type="password"
                                        autoComplete="off"
                                        value={props.values.password}
                                        onChange={props.handleChange}
                                        error={props.touched.password && !!props.errors.password}
                                    />
                                    <ErrorMessage name="password">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="phone"
                                        placeholder={t('register.phone.label')}
                                        type="tel"
                                        autoComplete="off"
                                        value={props.values.phone}
                                        onChange={props.handleChange}
                                        error={props.touched.phone && !!props.errors.phone}
                                    />
                                    <ErrorMessage name="phone">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="nif"
                                        placeholder={t('register.nif.label')}
                                        type="text"
                                        autoComplete="off"
                                        value={props.values.nif}
                                        onChange={props.handleChange}
                                        error={props.touched.nif && !!props.errors.nif}
                                    />
                                    <ErrorMessage name="nif">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <Box>
                                    <Input
                                        name="address"
                                        placeholder={t('register.address.label')}
                                        type="text"
                                        autoComplete="off"
                                        value={props.values.address}
                                        onChange={props.handleChange}
                                        error={props.touched.address && !!props.errors.address}
                                    />
                                    <ErrorMessage name="address">
                                        {(msg: string) => (
                                            <Typography color="common.white" variant="body2">
                                                {msg}
                                            </Typography>
                                        )}
                                    </ErrorMessage>
                                </Box>
                                <AppButton variant="contained" type="submit" disabled={isPending}>
                                    {t('register.submitButton')}
                                </AppButton>
                            </Form>
                        )}
                    </Formik>
                </FormBox>
            </Layout>
            <StyledBackgroundImage src="/images/football.jpg" />
        </>
    );
};

export default Register;
