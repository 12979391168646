import instance from './api.instance';
import axios, {AxiosResponse} from 'axios';
import {ClubData, Photo, PaymentMethods, ClubSchedule, ClubContacts} from '../models/clubDetails.model';
import {Category} from '../shared/types/Category.type';
import {Club} from '../shared/types/Club.type';
import {UserUpdate} from '../models/user.model';
import {Schedule, ClosedSchedule} from '../shared/types/Schedule.type';
import {ClubArea} from '../shared/types/ClubArea.type';
import {Organization} from '../models/organization.model';
import {BookingCreate} from '../pages/club-admin/bookings/types/Bookings.types';
import {Place} from '../components/Common/Input/Places';
import {ResetPassword} from '../shared/types/ResetPassword.type';
import {RequestAccount} from '../shared/types/RequestAccount.type';
import {VerifyAccount} from '../shared/types/VerifyAccount.type';

export class AppService {
    public async api_getAllOrganizations(domain: string): Promise<any> {
        return instance({
            url: `/private/organizations${!!domain ? `/?domain=${domain}` : ''}`,
            method: 'GET',
        });
    }

    public async api_getClubFeatureToggles(role: string, clubId: string): Promise<any> {
        return instance({
            url: `/${role}/club/${clubId}/feature-toggles`,
            method: 'GET',
        });
    }

    public async api_getDomainFeatureToggles(): Promise<any> {
        return instance({
            url: '/feature-toggles',
            method: 'GET',
        });
    }

    public async api_getReservationTypes(role: string): Promise<any> {
        return instance({
            url: `/${role}/reservation/reservation-categories`,
            method: 'GET',
        });
    }

    public async api_getClubsByOrganizationId(role: string, organizationId: string): Promise<any> {
        return instance({
            //depending on the user role we must pass public, private or admin
            url: `/${role}/organizations/${organizationId}/clubs`,
            method: 'GET',
        });
    }

    public async api_getMbwayPaymentStatus(reservationId: string): Promise<any> {
        return instance({
            url: `/public/reservation/${reservationId}/payments/mbway/status`,
            method: 'GET',
        });
    }

    public async api_getAvailableSlots(clubId: string, startDate: string, endDate: string): Promise<any> {
        return instance({
            url: `/public/club/${clubId}/available-slots?startDate=${startDate}&endDate=${endDate}`,
            method: 'GET',
        });
    }

    public async api_verifyAccount(verifyData: VerifyAccount): Promise<any> {
        try {
            const response = await instance({
                url: '/auth/player/validate',
                method: 'POST',
                data: verifyData,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_createClubOrganizations(clubId: string, organizationsData: Organization[]): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/organizations`,
                method: 'POST',
                data: {
                    organizationIds: organizationsData,
                },
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_getCategories(): Promise<AxiosResponse<Category[]>> {
        return instance({
            url: `/categories`,
            method: 'GET',
        });
    }

    public async api_getCategoriesWithResults(): Promise<AxiosResponse<Category[]>> {
        return instance({
            url: '/categories/active',
            method: 'GET',
        });
    }

    public async api_getClubNames(): Promise<AxiosResponse<string[]>> {
        return instance({
            url: `/club/clubNames`,
            method: 'GET',
        });
    }

    public async api_getClubs(role: string): Promise<AxiosResponse<any[]>> {
        return instance({
            //depending on the user role we must pass public, private or admin
            url: `/${role}/club`,
            method: 'GET',
        });
    }

    public async api_getUser(): Promise<any> {
        return instance({
            url: `/public/user`,
            method: 'GET',
        });
    }

    public async api_getAreaById(id: string): Promise<AxiosResponse<any>> {
        return instance({
            url: `/area/${id}`,
            method: 'GET',
        });
    }

    public async api_areaPhotos(role: string, areaId: string, areaPhotos: Photo[]): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/area/${areaId}/photo`,
                method: 'POST',
                data: {
                    photos: areaPhotos,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_deleteAreaPhotos(areaId: string, photosUrl: string[]): Promise<any> {
        try {
            const response = await instance({
                url: `/private/area/${areaId}/photo`,
                method: 'DELETE',
                data: {
                    photosUrl: photosUrl,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_deleteArea(areaId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/private/area/${areaId}`,
                method: 'DELETE',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getClubById(role: string, clubId: string): Promise<any> {
        return instance({
            //depending on the user role we must pass public, private or admin
            url: `/${role}/club/${clubId}`,
            method: 'GET',
        });
    }

    public async api_deleteAccount(role: string, password: string): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/user/${role === 'private' ? '' : 'player'}`,
                method: 'DELETE',
                data: {
                    password: password,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_createClub(role: string, clubData: ClubData): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club`,
                method: 'POST',
                data: clubData,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_createClubAddress(clubId: string, clubAddress: Place): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/address`,
                method: 'POST',
                data: clubAddress,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_createClubContact(clubId: string, contactData: ClubContacts[]): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/contact`,
                method: 'POST',
                data: {
                    contacts: [contactData],
                },
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_updateClubContact(contactId: string, contactData: ClubContacts): Promise<any> {
        try {
            const response = await instance({
                url: `/private/contact/${contactId}`,
                method: 'PUT',
                data: contactData,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_deleteClubContact(contactId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/private/contact/${contactId}`,
                method: 'DELETE',
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_getClub(role: string, data: any): Promise<AxiosResponse<Club>> {
        return instance({
            url: `${role !== 'open' ? `/${role}` : ''}/club/${data.clubId}`,
            method: 'GET',
        });
    }

    public async api_getHighlightedClubs(): Promise<any> {
        try {
            const response = await instance({
                url: `/club/highlighted`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_getClubAreas(role: string, clubId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club/${clubId}/area`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_getAreaSchedules(areaId: string): Promise<AxiosResponse<any>> {
        return instance({
            url: `/private/area/${areaId}/schedule`,
            method: 'GET',
        });
    }

    public async api_getClosedSchedules(areaId: string): Promise<AxiosResponse<any>> {
        return instance({
            url: `/private/area/${areaId}/closed-schedule`,
            method: 'GET',
        });
    }

    public async api_createAreaSchedules(areaId: string, schedules: Schedule[]): Promise<any> {
        try {
            const response = await instance({
                url: `/private/area/${areaId}/schedule`,
                method: 'POST',
                data: {
                    schedules: schedules,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_createClosedSchedules(areaId: string, closedSchedules: ClosedSchedule[]): Promise<any> {
        try {
            const response = await instance({
                url: `/private/area/${areaId}/closed-schedule`,
                method: 'POST',
                data: {
                    schedules: closedSchedules,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_createArea(areaData: ClubArea): Promise<any> {
        try {
            const response = await instance({
                url: '/private/area',
                method: 'POST',
                data: areaData,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_updateArea(areaId: string, areaData: any): Promise<any> {
        try {
            const response = await instance({
                url: `/private/area/${areaId}`,
                method: 'PUT',
                data: areaData,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_createAreaCategories(areaId: string, categoryData: Category): Promise<any> {
        try {
            const response = await instance({
                url: `/private/area/${areaId}/category`,
                method: 'POST',
                data: categoryData,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_searchAreas(role: string, searchObj = {}) {
        return instance({
            url: `/${role}/area/search`,
            method: 'GET',
            params: {...searchObj},
        });
    }

    public async api_searchClubs(searchObj = {}, role: string | undefined) {
        return instance({
            url: `${!!role ? `/${role}` : ''}/club/search`,
            method: 'GET',
            params: searchObj,
        });
    }

    public async api_getClubSchedules(clubId: string): Promise<AxiosResponse<any>> {
        return instance({
            url: `/private/club/${clubId}/schedule`,
            method: 'GET',
        });
    }

    public async api_createClubSchedules(clubId: string, schedules: Schedule[]): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/schedule`,
                method: 'POST',
                data: {
                    schedules: schedules,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getBookingById(bookingId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/public/reservation/${bookingId}`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_getClubBookings(role: string, clubId: string | undefined, data?: any): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club/${clubId}/reservation`,
                method: 'GET',
                params: data,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_getReservationHistory(role: string, clubId: string | undefined, data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club/${clubId}/reservation/history`,
                method: 'GET',
                params: data,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_deleteBooking(role: string, bookingId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/reservation/${bookingId}`,
                method: 'DELETE',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_changePassword(role: string, oldPassword: string, newPassword: string): Promise<any> {
        try {
            const response = await instance({
                url: `/public/user/${role}/password`,
                method: 'PATCH',
                data: {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.message;
            }
        }
    }

    public async api_updateUser(user: UserUpdate): Promise<any> {
        try {
            const response = await instance({
                url: `/public/user`,
                method: 'PUT',
                data: user,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_updateClub(role: string, clubId: string, clubData: ClubData): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club/${clubId}`,
                method: 'PUT',
                data: clubData,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_clubAddress(role: string, clubId: string, address: Place): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club/${clubId}/address`,
                method: 'POST',
                data: address,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_clubPhotos(role: string, clubId: string, clubPhotos: Photo[]): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club/${clubId}/photo`,
                method: 'POST',
                data: {
                    photos: clubPhotos,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_updateClubPhotoDefault(clubId: string, data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/photo`,
                method: 'PUT',
                data: data,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_deleteClubPhotos(clubId: string, photosUrl: string[]): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/photo`,
                method: 'DELETE',
                data: {
                    photosUrl: photosUrl,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_clubPayments(role: string, clubId: string, payments: PaymentMethods[]): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/club/${clubId}/payment-method`,
                method: 'POST',
                data: {
                    clubId: clubId,
                    payments: payments,
                },
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_requestClubAdminAccount(data: RequestAccount): Promise<any> {
        try {
            const response = await instance({
                url: `/user/club-admin`,
                method: 'POST',
                data: data,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_changeTemporaryPassword(role: string, data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/auth/${role}/temporary-password`,
                method: 'PATCH',
                data: data,
            });
            return response;
        } catch (e) {
            return axios.isAxiosError(e) ? e.response : e;
        }
    }

    public async api_resetPassword(role: string, data: ResetPassword): Promise<any> {
        try {
            const response = await instance({
                url: `/user/${role}/reset-password`,
                method: 'POST',
                data: data,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_createBooking(role: string, data: BookingCreate): Promise<any> {
        try {
            const response = await instance({
                url: `/public/reservation`,
                method: 'POST',
                data: data,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_registerPaymentProof(reservationId: string, paymentProof: Photo[]): Promise<any> {
        return instance({
            url: `/public/reservation/${reservationId}/payment-proof`,
            method: 'POST',
            data: {
                photo: paymentProof,
                reservationId: reservationId,
            },
        });
    }

    public async api_requestCodeForResetPassword(role: string, data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/user/${role}/code-for-password`,
                method: 'GET',
                params: data,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_registerUser(role: string, data: any): Promise<AxiosResponse<any>> {
        return instance({
            url: `/auth/${role}/register`,
            method: 'POST',
            data: data,
        });
    }

    public async api_getBookingsByUser(startDate: string, endDate: string): Promise<any> {
        try {
            const response = await instance({
                url: `/public/reservation/user?startDate=${startDate}&endDate=${endDate}`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_loginWithGoogle(data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/auth/player/external-provider`,
                method: 'GET',
                params: data,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getBookingByShareId(data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/reservation/detail/${data.shareId}`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_setInterestInBooking(data: any): Promise<any> {
        try {
            const response = await instance({
                url: `public/reservation/${data.id}/interest`,
                method: data.isInterested ? 'POST' : 'DELETE',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_login(role: string, data: any): Promise<any> {
        console.log('api_login');
        try {
            const response = await instance({
                url: `auth/${role}/login`,
                method: 'POST',
                data: data,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_deleteClub(clubId: string): Promise<AxiosResponse<any>> {
        return instance({
            url: `/private/club/${clubId}`,
            method: 'DELETE',
        });
    }

    public async api_getPaymentMethods(): Promise<any> {
        try {
            const response = await instance({
                url: `/private/payment-methods`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_updateBooking(role: string, data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/${role}/reservation/${data.id}`,
                method: 'PUT',
                data: data,
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_updateClubCategory(categories: Record<string, string>, clubId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/category`,
                method: 'PUT',
                data: {categories: categories},
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_updateClubSchedule(schedules: ClubSchedule, clubId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/private/club/${clubId}/schedule`,
                method: 'PUT',
                data: {schedules: schedules},
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getReservationsByArea(
        startDate: string,
        endDate: string,
        clubId: string,
        areaId?: string
    ): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/count-per-area?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getReservationsBySport(
        clubId: string,
        startDate?: string,
        endDate?: string,
        areaId?: string
    ): Promise<any> {
        let url = `/private/reservation/count-per-sport?clubId=${clubId}`;
        if (startDate) url += `&startDate=${startDate}`;
        if (endDate) url += `&endDate=${endDate}`;
        if (areaId) url += `&areaId=${areaId}`;
        try {
            const response = await instance({
                url: url,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getReservationsByType(
        startDate: string,
        endDate: string,
        clubId: string,
        areaId?: string
    ): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/count-per-type?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getReservationsByDays(
        startDate: string,
        endDate: string,
        clubId: string,
        areaId?: string
    ): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/count-per-weekday?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getReservationsByHour(
        startDate: string,
        endDate: string,
        clubId: string,
        areaId?: string
    ): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/count-per-hour?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getCanceledPaymentsByField(
        startDate: string,
        endDate: string,
        clubId: string,
        areaId?: string
    ): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/canceled-payments-per-field?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getTopUsers(startDate: string, endDate: string, clubId: string, areaId?: string): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/top-users?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getPaymentsByMethod(
        clubId: string,
        startDate?: string,
        endDate?: string,
        areaId?: string
    ): Promise<any> {
        let url = `/private/reservation/payments-per-method?clubId=${clubId}`;
        if (startDate) url += `&startDate=${startDate}`;
        if (endDate) url += `&endDate=${endDate}`;
        if (areaId) url += `&areaId=${areaId}`;
        try {
            const response = await instance({
                url: url,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getIncomeByDays(days: number): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/last-days-profit?days=${days}`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getPaymentInfo(startDate: string, endDate: string, clubId: string, areaId?: string): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/payment-info?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getCountAndEvolution(
        startDate: string,
        endDate: string,
        clubId: string,
        areaId?: string
    ): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/count-and-evolution?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}${
                    !!areaId ? `&areaId=${areaId}` : ''
                }`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_getIncomeByType(days: number): Promise<any> {
        try {
            const response = await instance({
                url: `/private/reservation/count-per-type?years=${days}`,
                method: 'GET',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_creditCardPayment(reservationId: string): Promise<any> {
        try {
            const response = await instance({
                url: `/public/reservation/${reservationId}/payments/ccard`,
                method: 'POST',
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }

    public async api_mbwayPayment(data: any): Promise<any> {
        try {
            const response = await instance({
                url: `/public/reservation/${data.reservationId}/payments/mbway`,
                method: 'POST',
                data: {mobilePhone: data.mobilePhone},
            });
            return response;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                return e.response;
            }
        }
    }
}
