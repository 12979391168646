import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import {styled} from '@mui/material/styles';

export const TextFieldStyled = styled(TextField)`
    font-size: 1rem;
    width: 100%;
    border-radius: 4;
    ::before {
        border-bottom-style: none;
    },

    & .MuiOutlinedInput-root {
        border-color: transparent;
        background: #fff;
    },

    & .Mui-disabled {
        background-color: var(--grey-lighten-3);
        border-color: transparent;
    },

    & .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        box-shadow: var(--default-boxShadow);
    },

    & .MuiFilledInput-root {
        border-radius: 4;
        ::after {
            border-bottom-style: none;
        },
    },

    & .MuiFilledInput-input {
        border-radius: 4;
    },

    & .Mui-error {
        background: #ffebee;
        ::before: {
            border-bottom-style: none;
    },

    & .MuiFormHelperText-root {
        color: #d32f2f;
        background: none;
    },
`;

export const InputLabelStyled = styled(InputLabel)`
    line-height: 2rem;
    color: var(--custom-secondary);
    font-size: 1rem;

    .MuiInputLabel-asterisk {
        color: red;
    }
`;
