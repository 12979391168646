import {Alert, AlertTitle} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {MessageEventType} from '../shared/enums/MessageEventType.enum';

const PaymentSuccess = () => {
    const {t} = useTranslation();

    useEffect(() => {
        window.parent.postMessage(MessageEventType.SUCCESS);
    }, []);

    return (
        <Alert variant="filled" severity="success">
            <AlertTitle>{t('paymentStatus.successTitle')}</AlertTitle>
            {t('paymentStatus.successMessage')}
        </Alert>
    );
};

export default PaymentSuccess;
