import React, {FC, useState} from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useTranslation} from 'react-i18next';
import {InputAdornment} from '@mui/material';
import {CalendarIcon} from '@mui/x-date-pickers';
import {DateRangePicker} from 'react-date-range';
import {addDays, addMonths, addYears} from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {ptBR} from 'date-fns/locale';
import {DateRangePickerBox, DateRangePickerCalendar, StyledInput} from './DashboardFilters.styles';

dayjs.extend(utc);

interface DashBoardFiltersProps {
    values: any;
    setFieldValue: (field: string, value: any) => void;
}

export const MUIDateRangePicker: FC<DashBoardFiltersProps> = ({values, setFieldValue}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    const [range, setRange] = useState([
        {
            startDate: addMonths(new Date(), -1),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const handleDateRangeChange = (item: any) => {
        const {startDate, endDate} = item.selection;
        const formattedRange = `${dayjs(startDate).format('YYYY-MM-DD')} - ${dayjs(endDate).format('YYYY-MM-DD')}`;
        setFieldValue('dateRangePicker', formattedRange);
        setOpen(false);
    };

    return (
        <DateRangePickerBox>
            <StyledInput
                id="dateRangePicker"
                name="dateRangePicker"
                label={t('clubAdmin.bookings.selectDate')}
                type="text"
                value={values.dateRangePicker}
                onClick={() => setOpen(!open)}
                error={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <CalendarIcon fontSize="small" />
                        </InputAdornment>
                    ),
                }}
            />
            {open && (
                <DateRangePickerCalendar>
                    <DateRangePicker
                        onChange={handleDateRangeChange}
                        locale={ptBR}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        staticRanges={[
                            {
                                label: t('dashboard.lastWeek'),
                                isSelected: () => false,
                                range: () => ({
                                    startDate: addDays(new Date(), -7),
                                    endDate: new Date(),
                                }),
                            },
                            {
                                label: t('dashboard.last2Weeks'),
                                isSelected: () => false,
                                range: () => ({
                                    startDate: addDays(new Date(), -14),
                                    endDate: new Date(),
                                }),
                            },
                            {
                                label: t('dashboard.last30Days'),
                                isSelected: () => false,
                                range: () => ({
                                    startDate: addMonths(new Date(), -1),
                                    endDate: new Date(),
                                }),
                            },
                            {
                                label: t('dashboard.last90Days'),
                                isSelected: () => false,
                                range: () => ({
                                    startDate: addMonths(new Date(), -2),
                                    endDate: new Date(),
                                }),
                            },
                            {
                                label: t('dashboard.lastYear'),
                                isSelected: () => false,
                                range: () => ({
                                    startDate: addYears(new Date(), -1),
                                    endDate: new Date(),
                                }),
                            },
                        ]}
                        inputRanges={[]}
                        ranges={range}
                        direction="horizontal"
                    />
                </DateRangePickerCalendar>
            )}
        </DateRangePickerBox>
    );
};
