import {Chip, IconButton} from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';
import React from 'react';
import {findAreaNameById} from '../../../../utils/filters';
import {FilterAlt as FilterAltIcon, FilterAltOff as FilterAltOffIcon} from '@mui/icons-material';
import {ClubArea} from '../../../../shared/types/ClubArea.type';
import {TFunction} from 'i18next';
import {StyledMobileFilter} from '../Filters.styles';

type MobileFilterProps = {
    values: {
        selectedArea: string;
        selectedSports: string[];
        startDate: Dayjs | null;
        endDate: Dayjs | null;
        search: string;
        users?: string[];
    };
    t: TFunction;
    isFilterOpen: boolean;
    setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
    areas: ClubArea[];
};

export const MobileFilter = ({values, t, isFilterOpen, setIsFilterOpen, areas}: MobileFilterProps) => {
    return (
        <StyledMobileFilter>
            {values.selectedArea !== '' && (
                <Chip
                    label={
                        values.selectedArea === 'all'
                            ? t('clubAdmin.bookings.allAreas')
                            : findAreaNameById(values.selectedArea, areas)
                    }
                />
            )}
            {values.selectedSports?.length > 0 && (
                <Chip label={`${t('club.headers.sports')}: ${values.selectedSports.join(', ')}`} />
            )}
            {values.startDate !== null && (
                <Chip label={`${t('from')}: ${dayjs(values.startDate).format('DD-MM-YYYY')}`} />
            )}
            {values.endDate !== null && <Chip label={`${t('to')}: ${dayjs(values.endDate).format('DD-MM-YYYY')}`} />}
            {values.search !== '' && <Chip label={`${t('query')}: ${values.search}`} />}
            <IconButton
                aria-label="filter toggle"
                onClick={() => setIsFilterOpen((prev) => !prev)}
                sx={{marginLeft: 'auto'}}
            >
                {isFilterOpen ? <FilterAltOffIcon /> : <FilterAltIcon />}
            </IconButton>
        </StyledMobileFilter>
    );
};
