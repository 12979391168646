import {useQuery} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {useCommon} from '../context/CommonContext';
import {useEffect} from 'react';

export function useAvailableSlots(clubId: string, startDate: string, endDate: string) {
    const appService = new AppService();
    const {setIsShareButtonsVisible} = useCommon();

    const {
        isLoading,
        data = [],
        isError,
    } = useQuery({
        queryKey: ['availableSlots', clubId, startDate, endDate],
        queryFn: async () => {
            const res = await appService.api_getAvailableSlots(clubId, startDate, endDate);
            return res.data;
        },
        retry: false,
    });

    useEffect(() => {
        if (isError) {
            setIsShareButtonsVisible(false);
        } else if (data.length > 0) {
            setIsShareButtonsVisible(true);
        }
    }, [isError, data, setIsShareButtonsVisible]);

    return {data, isLoading, isError};
}
