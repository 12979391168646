import {useMutation} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {VerifyAccount} from '../shared/types/VerifyAccount.type';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getURLWithReturn} from '../utils/returnUrl';
import {useTranslation} from 'react-i18next';
import {useCommon} from '../context/CommonContext';

export function useVerifyAccount() {
    const appService = new AppService();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const return_url = searchParams.get('return_url');
    const {t} = useTranslation();
    const {setSnackbar} = useCommon();

    const {isPending, mutate} = useMutation({
        mutationKey: ['verifyAccount'],
        mutationFn: async (verifyData: VerifyAccount) => {
            const res = await appService.api_verifyAccount(verifyData);
            return res;
        },
        retry: false,
        onError: (error) => {
            setSnackbar({
                open: true,
                severity: 'error',
                children: t('verify.error.notVerified'),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
            });
        },
        onSuccess: (data) => {
            setSnackbar({
                open: true,
                severity: 'success',
                children: t('verify.successMessage'),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
            });
            navigate(getURLWithReturn('/login', return_url));
        },
    });

    return {isPending, mutate};
}
