import {Alert, AlertTitle} from '@mui/material';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {MessageEventType} from '../shared/enums/MessageEventType.enum';

const PaymentCanceled = () => {
    const {t} = useTranslation();

    useEffect(() => {
        window.parent.postMessage(MessageEventType.CANCELED);
    }, []);

    return (
        <Alert variant="filled" severity="warning">
            <AlertTitle>{t('paymentStatus.canceledTitle')}</AlertTitle>
            {t('paymentStatus.canceledMessage')}
        </Alert>
    );
};

export default PaymentCanceled;
