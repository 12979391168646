import {DatePicker, DatePickerProps} from '@mui/x-date-pickers';
import {Dayjs} from 'dayjs';
import styled from 'styled-components';

export const StyledDatePicker = styled(DatePicker)<DatePickerProps<Dayjs>>`
    border: transparent;
    border-radius: 4px;

    & .MuiInputBase-root {
        box-shadow: var(--default-boxShadow);
    }

    & .MuiInputBase-root {
        background-color: #fff;
    }

    & .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }

    & .Mui-error {
        background-color: #ffebee;
        color: #d32f2f !important;
    }

    & .MuiFormHelperText-root {
        background-color: transparent;
        color: #d32f2f;
    }
`;
