import React, {useContext, useEffect, useState} from 'react';
import {MenuItem, Theme, useMediaQuery, useTheme} from '@mui/material';
import {FeatureToggleBaseComponent} from '../../../../components/featureToggleBaseComponent/FeatureToggleBaseComponent';
import {AvailableSlots} from '../../../available-slots-page/components/AvailableSlots';
import ShareIcon from '@mui/icons-material/Share';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {Club} from '../../../../models/club.model';
import {useTranslation} from 'react-i18next';
import {useClubs} from '../../../../hooks/useClubs';
import {FeatureToggles} from '../../../../shared/enums/FeatureToggles.enum';
import {useCommon} from '../../../../context/CommonContext';
import {AppContext} from '../../../../context/AppContext';
import {SelectClub, SelectClubBox, StyledDialogContent, StyledHeader} from '../Filters.styles';

export const SelectedClubFilter = () => {
    const {t} = useTranslation();
    const {setModal} = useCommon();
    const theme: Theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isDashbord = window.location.pathname.includes('dashboard');
    //AppContext
    const appContext = useContext(AppContext);
    const club = appContext.club;
    const [selectedClubId, setSelectedClubId] = useState('all');
    const handleClubChange = (event: any) => {
        setSelectedClubId(event.target.value);
        const newClub = clubs.find((club: Club) => club.id === event.target.value);
        appContext.setClub(newClub);
    };
    const {data: clubs} = useClubs('private');

    useEffect(() => {
        if (club && clubs?.some((c) => c.id === club.id)) {
            setSelectedClubId(club.id);
        } else {
            setSelectedClubId('all');
        }
    }, [club, clubs]);

    return (
        <StyledHeader>
            <SelectClubBox>
                <SelectClub
                    name="selectedClub"
                    labelId="selectedClub"
                    value={selectedClubId}
                    onChange={handleClubChange}
                >
                    <MenuItem value="all">{t('clubAdmin.bookings.allClubs')}</MenuItem>
                    {clubs?.map((club: Club) => (
                        <MenuItem key={club.id} value={club.id}>
                            {club?.name}
                        </MenuItem>
                    ))}
                </SelectClub>
                {!isDashbord && (
                    <FeatureToggleBaseComponent featureToggle={FeatureToggles.SHARE_AVAILABLE_SLOTS}>
                        <AppButton
                            variant={!isSmallScreen ? 'contained' : 'text'}
                            startIcon={!isSmallScreen ? <ShareIcon /> : null}
                            disabled={selectedClubId === 'all' ? true : false}
                            onClick={() => {
                                setModal({
                                    open: true,
                                    children: (
                                        <StyledDialogContent dividers>
                                            <AvailableSlots />
                                        </StyledDialogContent>
                                    ),
                                    title: t('booking.shareAvailableSlots'),
                                });
                            }}
                        >
                            {!isSmallScreen ? t('booking.button.share') : <ShareIcon />}
                        </AppButton>
                    </FeatureToggleBaseComponent>
                )}
            </SelectClubBox>
        </StyledHeader>
    );
};
