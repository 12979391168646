import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {useCommon} from '../context/CommonContext';
import {useTranslation} from 'react-i18next';
export function useDeleteArea() {
    const appService = new AppService();
    const {setSnackbar, setModal} = useCommon();
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const refetchClubByIdQuery = () => {
        queryClient.invalidateQueries({queryKey: ['clubById']});
    };
    const {isPending, data, mutate} = useMutation({
        mutationKey: ['deleteArea'],
        mutationFn: async (areaId: string) => {
            const res = await appService.api_deleteArea(areaId);
            return res;
        },
        onSuccess: (data) => {
            setModal({open: false, children: null});
            refetchClubByIdQuery();
        },
        onError: () => {
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('generalError'),
            });
        },
    });
    return {data, isPending, mutate};
}
