import {CircularProgress} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {CountdownBox, LabelBox, CountdownText} from './CountdownTimer.styles';

interface CountdownTimerProps {
    duration: number;
    onComplete: () => void;
}

export const CountdownTimer: React.FC<CountdownTimerProps> = ({duration, onComplete}) => {
    const [timeDuration, setTimeDuration] = useState<number>(duration);
    const [countdownText, setCountdownText] = useState<string>('');
    const [countdownPercentage, setCountdownPercentage] = useState<number>(100);
    const [countdownColor, setCountdownColor] = useState<string>('#cccccc');

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = setInterval(() => {
            setTimeDuration((prev) => {
                const newTimeDuration = prev - 1;
                const percentage = Math.ceil((newTimeDuration / duration) * 100);
                setCountdownPercentage(percentage);

                if (newTimeDuration === 0) {
                    clearInterval(intervalId!);
                    intervalId = null;
                    onComplete();
                }

                return newTimeDuration;
            });
        }, 1000);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
                intervalId = null;
            }
        };
    }, [duration, onComplete]);

    useEffect(() => {
        const minutes = Math.floor(timeDuration / 60);
        const seconds = timeDuration % 60;
        setCountdownText(`${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`);
    }, [timeDuration]);

    return (
        <CountdownBox>
            <CircularProgress
                variant="determinate"
                size={85}
                thickness={4}
                value={100}
                sx={{color: 'var(--color-primary)', position: 'absolute'}}
            />
            <CircularProgress
                variant="determinate"
                size={85}
                thickness={4}
                value={countdownPercentage}
                style={{
                    transform: 'scaleX(-1) rotate(-90deg)',
                    color: countdownColor,
                }}
            />
            <LabelBox>
                <CountdownText>{countdownText}</CountdownText>
            </LabelBox>
        </CountdownBox>
    );
};
