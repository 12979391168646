import dayjs from 'dayjs';

export const passwordExpression = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^A-Za-z0-9]).{8,}$';
export const passwordRegex = new RegExp(passwordExpression);
export const isPasswordValid = (password: string) => {
    return passwordRegex.test(password);
};

export const emailRegex = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const isEmailValid = (email: string) => emailRegex.test(email);

export const postalCodeRegex = /^\d{4}(?:[-\s]\d{3})?$/g;
export const isPostalCodelValid = (postalCode: string) => postalCodeRegex.test(postalCode);

export const swiftRegex = /^[A-Z]{4}PT[A-Z]{2}$/g;
export const isSwiftValid = (swift: string) => swiftRegex.test(swift);

export const ibanRegex = /^(?:(?:[A-Z]{2}\d{2})(\d{4})(\d{4})(\d{11})(\w{0,16})|(\d{4})(\d{4})(\d{11})(\w{0,16}))$/g;
export const isIbanValid = (iban: string) => ibanRegex.test(iban);

// Visa and Mastercard regex
export const visaAndMasterCardRegex =
    /^(?:4[0-9]{3}(?:\s?[0-9]{4}){2}(?:\s?[0-9]{4})|(5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[0-1][0-9]|2720)(?:\s?[0-9]{4}){3})$/;
export const isCreditCardValid = (creditCardNumber: string) => visaAndMasterCardRegex.test(creditCardNumber);

// Visa, MasterCard and Discover CVV regex
export const visaAndMasterCVVRegex = /^\d{3}$/;
export const isCVVValid = (cvv: string) => visaAndMasterCVVRegex.test(cvv);

// expiration date regex
const currentDate = dayjs(new Date()).format('MM/ YY');
const regexString = `^(0[1-9]|1[0-2])\\/ (${currentDate.slice(-2)})$`;
export const expirationDateRegex = new RegExp(regexString);

export const isExpirationDateValid = (expirationDate: string) => expirationDateRegex.test(expirationDate);

// PT mobilephone number regex in format 9X XXX XXXX
export const mobilePhoneNumberRegex = /^9[1236]\s?\d{3}\s?\d{4}$/;
export const isPtMobilePhoneNumberValid = (mobilePhone: string) => mobilePhoneNumberRegex.test(mobilePhone);
