import {useMutation} from '@tanstack/react-query';
import {AppService} from '../services/app.service';

export function useMBWayPayment() {
    const appService = new AppService();

    const {mutate, isPending} = useMutation({
        mutationKey: ['mbwayPayment'],
        mutationFn: async (paymentData: any) => {
            const res = await appService.api_mbwayPayment(paymentData);
            return res;
        },
    });

    return {mutate, isPending};
}
