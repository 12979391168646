import React, {FC} from 'react';
import {DialogActions, DialogContent} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {StyledAppButton} from '../BookingModal/BookingModal.styles';
import {Booking} from '../../models/booking.model';
import {useDeleteBooking} from '../../hooks/useDeleteBooking';
import {useCommon} from '../../context/CommonContext';

export type BookingCancelModalProps = {
    booking: Booking;
};

export const BookingCancelModal: FC<BookingCancelModalProps> = ({booking}) => {
    const {t} = useTranslation();
    const {mutate, isPending} = useDeleteBooking('public');
    const {setModal} = useCommon();
    const handleCloseModal = () => {
        setModal({open: false, children: <></>});
    };

    const handleDelete = () => {
        mutate(booking.id!);
    };

    return (
        <>
            <DialogContent dividers>{t('cancelBookingMessage', {email: booking?.email})}</DialogContent>
            <DialogActions>
                <StyledAppButton variant="outlined" disabled={isPending} onClick={handleCloseModal}>
                    {t('return')}
                </StyledAppButton>
                <StyledAppButton color="error" variant="contained" disabled={isPending} onClick={handleDelete}>
                    {t('cancelBooking')}
                </StyledAppButton>
            </DialogActions>
        </>
    );
};
