import {useMutation, useQueryClient} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {useCommon} from '../context/CommonContext';
import {useTranslation} from 'react-i18next';

export function useDeleteBooking(role: string) {
    const appService = new AppService();
    const queryClient = useQueryClient();
    const {setSnackbar, setModal} = useCommon();
    const userRole = localStorage.getItem('role');

    const {t} = useTranslation();

    const refetchBookingsQuery = () => {
        if (userRole && userRole === 'player') {
            queryClient.invalidateQueries({queryKey: ['getBookingsByUser']});
        }
        if (userRole && userRole === 'club-admin') {
            queryClient.invalidateQueries({queryKey: ['clubBookings']});
        }
    };

    const {isPending, data, mutate} = useMutation({
        mutationKey: ['deleteBooking'],
        mutationFn: async (bookingId: string) => {
            const res = await appService.api_deleteBooking(role, bookingId);
            return res;
        },
        onSuccess: (data) => {
            refetchBookingsQuery();
            setModal({open: false, children: null});
        },
        onError: () => {
            setSnackbar({
                open: true,
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t('cancelBookingAlertMessage.500'),
            });
        },
    });

    return {data, isPending, mutate};
}
