import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import type {ChartData} from 'chart.js';
import {Club} from '../../../../shared/types/Club.type';
import {ClubArea} from '../../../../shared/types/ClubArea.type';
import {TFunction} from 'i18next';
import {monthNamesMapping} from '../../../../utils/months';
import {timeMapping} from '../../../../utils/time';
import {weekDaysMapping} from '../../../../utils/week-days';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);
const dataBackgroundColor = [
    '#006064',
    '#ef5350',
    '#ffa726',
    '#9c27b0',
    '#26a69a',
    '#2196f3',
    '#c2185b',
    '#311b92',
    '#0d47a1',
    '#1a237e',
];

export function mapByAreaData(data: any, areas: ClubArea[] | undefined): ChartData<'pie'> {
    const areaData: number[] = [];
    const labels: string[] = [];
    const numAreas = Object.keys(data).length;
    const colors = Array.from({length: numAreas}, (_, i) => dataBackgroundColor[i]);

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const areaId = key;
            const area = areas?.find((area: any) => area.id === areaId);

            if (area) {
                labels.push(area.name);
            } else {
                labels.push(areaId);
            }

            const value = data[key];
            if (typeof value === 'number') {
                areaData.push(value);
            } else {
                areaData.push(0);
            }
        }
    }

    return {
        labels: labels,
        datasets: [
            {
                data: areaData,
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    };
}

export function doughnutToPie(data: ChartData<'doughnut'>): ChartData<'pie'> {
    return data as unknown as ChartData<'pie'>;
}

export function mapBySportsData(data: any): ChartData<'doughnut'> {
    const dataset = [];
    const labels = [];
    const colors = [];
    for (const row in data) {
        dataset.push(data[row]);
        labels.push(row);
        colors.push(dataBackgroundColor);
    }

    return {
        labels: labels,
        datasets: [
            {
                label: 'Reservations',
                data: dataset,
                backgroundColor: dataBackgroundColor,
                borderWidth: 0,
            },
        ],
    };
}

export function mapByDaysData(data: any, days: number): ChartData<'bar'> {
    let maxCount = 0;
    for (const item of data) {
        if (item.count > maxCount) {
            maxCount = item.count;
        }
    }

    const dataset = [];
    const labels = data.map((item: any) => weekDaysMapping[item.day][0]);
    const colors = [];
    for (let i = 0; i < days; i++) {
        dataset.push(data[i] ? data[i].count : 0);
        colors.push('#0D47A1');
    }
    return {
        labels,
        datasets: [
            {
                label: 'Reservations',
                data: dataset,
                backgroundColor: colors,
                borderRadius: {
                    topLeft: 100,
                    topRight: 100,
                    bottomLeft: 100,
                    bottomRight: 100,
                },
                borderSkipped: 'bottomLeft' as any,
                maxBarThickness: 10,
            },
        ],
    };
}

export function mapIncomeByDaysData(data: any, days: number): any {
    const dataset = data['data'];
    const labels = [];
    for (const row in dataset) {
        labels.push(days);
        days--;
    }

    return {
        labels: labels,
        datasets: [
            {
                label: 'Income',
                data: dataset,
                borderColor: ['#1970e2'],
                backgroundColor: ['#1970e2'],
                borderWidth: 4,
                tension: 0.5,
                pointRadius: 1,
                fill: {
                    target: 'origin',
                    above: 'rgba(25, 112, 226, 0.1)',
                    below: 'rgba(255, 255, 255, 1)',
                },
            },
        ],
    };
}

export function mapReservationsByType(data: any, t: TFunction): ChartData<'bar'> {
    const datasets = [];
    let i = 0;
    for (const row in data) {
        datasets.push({
            label: t(`clubAdmin.bookings.reservationTypes.${row.toLowerCase()}`),
            data: data[row],
            backgroundColor: dataBackgroundColor[i++],
        });
    }

    const monthNames = monthNamesMapping(t);
    const labels: string[] = [];
    const currentMonth = new Date().getMonth();

    for (let i = 0; i <= currentMonth + 1; i++) {
        labels.push(monthNames[i]);
    }

    return {
        labels,
        datasets,
    };
}

export function mapPaymentsByMethod(data: any): ChartData<'doughnut'> {
    const dataset = [];
    const labels = [];
    for (const row in data) {
        dataset.push(data[row]);
        labels.push(row);
    }

    return {
        labels: labels,
        datasets: [
            {
                label: 'Payment method',
                data: dataset,
                backgroundColor: dataBackgroundColor,
                borderWidth: 0,
            },
        ],
    };
}

export function mapByHourData(data: any, club: Club | null): ChartData<'line'> {
    const datasets = [];
    const numAreas = Object.keys(data).length;
    const colors = Array.from({length: numAreas}, (_, i) => dataBackgroundColor[i]);

    for (const row in data) {
        let label = row;
        for (const area of club?.areas ?? []) {
            if (area.id == row) {
                label = area.name;
                break;
            }
        }
        const filteredData = data[row].slice(7, 20);
        datasets.push({
            label,
            data: filteredData,
            borderColor: colors,
            backgroundColor: colors,
        });
    }

    const timeMap = timeMapping();
    const labels: string[] = [];
    for (let i = 7; i <= 19; i++) {
        labels.push(timeMap[i]);
    }

    return {
        labels,
        datasets,
    };
}
