import axios from 'axios';

const instance = axios.create({
    baseURL: `${
        window.location.host === 'localhost:3000' ? 'http://localhost:3001/api' : window.location.origin + '/api'
    }`,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        const token = response.headers['x-access-token'];

        if (token) {
            console.log("token instance");
            localStorage.setItem('token', token);
        }
        return response;
    },
    (error) => {
        const token = localStorage.getItem('token');
        if (error.response.status === 401 && !error.response.request.responseURL.includes('auth') && token) {
            window.location.href = `${window.location.origin}/logout`;
        }
        throw error;
    }
);

export default instance;
