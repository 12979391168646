import {useQuery} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {useContext} from 'react';
import {AppContext} from '../context/AppContext';

export function useClubBookings(role: string, clubId: string | undefined, fetchData: boolean, params: any) {
    const appService = new AppService();
    const appContext = useContext(AppContext);
    const clubNewBookings = appContext.newBookings;
    const bookingUpdate = appContext.bookingUpdate;

    const {isLoading, data = []} = useQuery({
        queryKey: ['clubBookings', params, clubId, clubNewBookings, bookingUpdate],
        queryFn: async () => {
            const res = await appService.api_getClubBookings(role, clubId, params);
            return res.data;
        },
        retry: false,
        enabled: fetchData,
    });

    return {data, isLoading};
}
