import {Box} from '@mui/material';
import styled from 'styled-components';

export const StyledBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px;
`;

export const CalendarContainer = styled(Box)`
    @media (max-width: 600px) {
        margin-top: 1rem;
    }
`;
