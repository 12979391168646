import {TFunction} from 'i18next';

export const monthNamesMapping = (t: TFunction): Record<number, string> => ({
    0: t('month.december'),
    1: t('month.january'),
    2: t('month.february'),
    3: t('month.march'),
    4: t('month.april'),
    5: t('month.may'),
    6: t('month.june'),
    7: t('month.july'),
    8: t('month.august'),
    9: t('month.september'),
    10: t('month.october'),
    11: t('month.november'),
});
