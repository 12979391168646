import React from 'react';
import {Input} from '../Common/Input/TextField/Input';
import {useTranslation} from 'react-i18next';
import {useCommon} from '../../context/CommonContext';
import {validationAdminRegisterSchema} from '../../models/validationAdminRegisterSchema.model';
import {ErrorMessage, Formik} from 'formik';
import {Box} from '@mui/material';
import {Form, StyledError} from './ClubAdminRegister.styles';
import {useRequestAccount} from '../../hooks/useRequestAccount';
import {RequestAccount} from '../../shared/types/RequestAccount.type';

export const ClubAdminRegister = () => {
    const {t} = useTranslation();
    const {setSnackbar, setModal} = useCommon();
    const {mutate: requestAccount} = useRequestAccount();

    const initialValues: RequestAccount = {
        name: '',
        clubName: '',
        email: '',
        phone: '',
        observations: '',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationAdminRegisterSchema(t)}
            onSubmit={async (values: RequestAccount, {resetForm}) => {
                requestAccount(values, {
                    onSuccess: (data) => {
                        if (data.status !== 200) throw new Error();
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t('clubAdmin.requestAccount.success'),
                        });
                        resetForm();
                        setModal({open: false, children: <></>});
                    },
                    onError: () => {
                        setSnackbar({
                            open: true,
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'error',
                            children: t('clubAdmin.requestAccount.error.onSubmit'),
                        });
                        setModal({open: false, children: <></>});
                    },
                });
            }}
        >
            {(props) => (
                <Form onSubmit={props.handleSubmit} id="form-clubAdminRegister" data-testid="club-admin-register-form">
                    <Box>
                        <Input
                            id="name"
                            name="name"
                            labelTop={t('clubAdmin.requestAccount.adminName')}
                            placeholder={t('clubAdmin.requestAccount.adminName')}
                            labelTopRequired={true}
                            type="text"
                            hasLabelTop={true}
                            autoComplete="off"
                            value={props.values.name}
                            onChange={props.handleChange}
                            error={props.touched.name && Boolean(props.errors.name)}
                        />
                        <ErrorMessage name="name">{(msg: string) => <StyledError>{msg}</StyledError>}</ErrorMessage>
                    </Box>
                    <Box>
                        <Input
                            id="clubName"
                            name="clubName"
                            labelTopRequired={true}
                            hasLabelTop={true}
                            labelTop={t('clubAdmin.requestAccount.clubName')}
                            placeholder={t('clubAdmin.requestAccount.clubName')}
                            type="text"
                            autoComplete="off"
                            value={props.values.clubName}
                            onChange={props.handleChange}
                            error={props.touched.clubName && Boolean(props.errors.clubName)}
                        />
                        <ErrorMessage name="clubName">{(msg: string) => <StyledError>{msg}</StyledError>}</ErrorMessage>
                    </Box>
                    <Box>
                        <Input
                            id="email"
                            name="email"
                            labelTopRequired={true}
                            hasLabelTop={true}
                            labelTop={t('clubAdmin.requestAccount.email')}
                            placeholder={t('clubAdmin.requestAccount.email')}
                            type="text"
                            autoComplete="off"
                            value={props.values.email}
                            onChange={props.handleChange}
                            error={props.touched.email && Boolean(props.errors.email)}
                        />
                        <ErrorMessage name="email">{(msg: string) => <StyledError>{msg}</StyledError>}</ErrorMessage>
                    </Box>
                    <Box>
                        <Input
                            id="phone"
                            name="phone"
                            labelTopRequired={true}
                            hasLabelTop={true}
                            labelTop={t('clubAdmin.requestAccount.phone')}
                            placeholder={t('clubAdmin.requestAccount.phone')}
                            type="text"
                            autoComplete="off"
                            value={props.values.phone}
                            onChange={props.handleChange}
                            error={props.touched.phone && Boolean(props.errors.phone)}
                        />
                        <ErrorMessage name="phone">{(msg: string) => <StyledError>{msg}</StyledError>}</ErrorMessage>
                    </Box>
                    <Box>
                        <Input
                            id="observations"
                            name="observations"
                            hasLabelTop={true}
                            labelTop={t('clubAdmin.requestAccount.observations')}
                            placeholder={t('clubAdmin.requestAccount.observations')}
                            type="text"
                            autoComplete="off"
                            value={props.values.observations}
                            onChange={props.handleChange}
                            error={props.touched.observations && Boolean(props.errors.observations)}
                        />
                        <ErrorMessage name="observations">
                            {(msg: string) => <StyledError>{msg}</StyledError>}
                        </ErrorMessage>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
