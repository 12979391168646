import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Checkbox, InputLabel, ListItemText, MenuItem, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {useTranslation} from 'react-i18next';
import {Category} from '../../../shared/types/Category.type';
import {HistoryDataList} from './components/HistoryDataList/HistoryDataList';
import {DesktopFilter, FilterButton, SelectGrid, StyledSelectControl, StyledError} from './HistoryData.styles';
import {ErrorMessage, Form, Formik} from 'formik';
import {Select} from '../../../components/Common/Select/Select';
import MUIDatePicker from '../../../components/DatePicker/DatePicker';
import {Input} from '../../../components/Common/Input/TextField/Input';
import {HistoryDataFormValues} from './types/HistoryData.types';
import {validationHistorySchema} from '../../../models/validationHistorySchema.model';
import {ClubArea} from '../../../shared/types/ClubArea.type';
import {Layout} from '../../../components/Common/Layout/Layout';
import {useClubById} from '../../../hooks/useClubById';
import {useClubHistory} from '../../../hooks/useClubHistory';

dayjs.extend(utc);
dayjs.locale(localStorage.getItem('i18netLng') ?? 'pt');

const HistoryData = () => {
    const {clubId} = useParams<{clubId?: string}>();
    const {t} = useTranslation();
    const {areas, sports} = useClubById('private', clubId!);

    const [initialValues, setInitialValues] = useState<HistoryDataFormValues>({
        selectedArea: '',
        selectedSports: [],
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month'),
        users: [],
        search: '',
    });
    const {data: bookings} = useClubHistory('private', clubId, true, {
        startDate: `${dayjs(initialValues.startDate).format('YYYY-MM-DD')}T00:00.000Z`,
        endDate: `${dayjs(initialValues.endDate).format('YYYY-MM-DD')}T22:59.999Z`,
        areaId: initialValues.selectedArea !== 'all' ? initialValues.selectedArea : '',
        sports: initialValues.selectedSports,
        search: initialValues.search,
    });

    return (
        <Layout headerType="menu" maxWidth={false}>
            <Stack alignItems="center" p={2} spacing={2}>
                <Typography variant="h5">{t('clubAdmin.menu.historyData')}</Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationHistorySchema(t)}
                    enableReinitialize={true}
                    onSubmit={async (values: HistoryDataFormValues) => {
                        setInitialValues(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                            <DesktopFilter isFilterOpen={true}>
                                <SelectGrid>
                                    <Box>
                                        <StyledSelectControl
                                            variant="outlined"
                                            error={props.touched.selectedArea && Boolean(props.errors.selectedArea)}
                                        >
                                            <InputLabel id="sports">{t('clubAdmin.bookings.selectArea')}</InputLabel>
                                            <Select
                                                name="selectedArea"
                                                labelId="selectedArea"
                                                value={props.values.selectedArea}
                                                onChange={props.handleChange}
                                            >
                                                <MenuItem value="all">{t('clubAdmin.bookings.allAreas')}</MenuItem>
                                                {areas?.map((area: ClubArea) => (
                                                    <MenuItem key={area.id} value={area.id}>
                                                        {area?.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </StyledSelectControl>
                                        <ErrorMessage name="selectedArea">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box>
                                    <Box>
                                        <StyledSelectControl
                                            variant="outlined"
                                            error={props.touched.selectedSports && Boolean(props.errors.selectedSports)}
                                        >
                                            <InputLabel id="sports">
                                                {t('clubAdmin.bookings.selectCategory')}
                                            </InputLabel>
                                            <Select
                                                name="selectedSports"
                                                labelId="selectedSports"
                                                value={props.values.selectedSports}
                                                multiple
                                                onChange={props.handleChange}
                                                renderValue={(selected: any) => selected.join(', ')}
                                            >
                                                {sports?.map((sport: Category) => (
                                                    <MenuItem key={sport} value={sport}>
                                                        <Checkbox
                                                            checked={props.values.selectedSports.indexOf(sport) > -1}
                                                        />
                                                        <ListItemText primary={sport} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </StyledSelectControl>
                                        <ErrorMessage name="sports">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box>
                                    <StyledSelectControl>
                                        <MUIDatePicker
                                            label={t('clubAdmin.historyData.selectStartDate')}
                                            value={props.values.startDate}
                                            slotProps={{
                                                textField: {
                                                    name: 'startDate',
                                                    error: props.errors.startDate as boolean | undefined,
                                                    helperText: props.errors.startDate as string,
                                                },
                                            }}
                                            onChange={(newValue) =>
                                                props.handleChange({target: {name: 'startDate', value: newValue}})
                                            }
                                        />
                                        <ErrorMessage name="startDate">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </StyledSelectControl>
                                    <StyledSelectControl>
                                        <MUIDatePicker
                                            label={props.errors.endDate ? '' : t('clubAdmin.historyData.selectEndDate')}
                                            value={props.values.endDate}
                                            slotProps={{
                                                textField: {
                                                    name: 'endDate',
                                                    error: props.errors.endDate as boolean | undefined,
                                                    helperText: props.errors.endDate as string,
                                                },
                                            }}
                                            onChange={(newValue) =>
                                                props.handleChange({target: {name: 'endDate', value: newValue}})
                                            }
                                        />
                                        <ErrorMessage name="endDate">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </StyledSelectControl>
                                    {/* 
                                    IN CASE WE WANT TO ADD THIS FILTER AS DESIGNED
                                    <Box>
                                        <StyledSelectControl
                                            variant="outlined"
                                            error={props.touched.users && Boolean(props.errors.users)}
                                        >
                                            <InputLabel id="users">{t('clubAdmin.historyData.selectUsers')}</InputLabel>
                                            <Select
                                                name="users"
                                                labelId="users"
                                                value={props.values.users}
                                                multiple
                                                onChange={props.handleChange}
                                                renderValue={(selected: any) => selected.join(', ')}
                                            >
                                                {users?.map((user: any) => (
                                                    <MenuItem key={user} value={user}>
                                                        <ListItemText primary={user} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </StyledSelectControl>
                                        <ErrorMessage name="users">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box> */}
                                    <Box>
                                        <Input
                                            name="search"
                                            placeholder={t('clubAdmin.bookings.inputLabel.search')}
                                            label={t('clubAdmin.bookings.inputLabel.search')}
                                            type="text"
                                            value={props.values.search}
                                            onChange={props.handleChange}
                                            error={props.touched.search && Boolean(props.errors.search)}
                                        />
                                        <ErrorMessage name="search">
                                            {(msg: string) => <StyledError>{msg}</StyledError>}
                                        </ErrorMessage>
                                    </Box>
                                    <FilterButton isFilterOpen={true} variant="contained" type="submit">
                                        {t('filter')}
                                    </FilterButton>
                                </SelectGrid>
                            </DesktopFilter>
                        </Form>
                    )}
                </Formik>
                <HistoryDataList historyData={bookings} />
            </Stack>
        </Layout>
    );
};

export default HistoryData;
