import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Chip, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {Delete, Edit} from '@mui/icons-material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import noImage from '../../assets/images/noImage.svg';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import {AreaCardStyled} from './AreaCard.styles';

dayjs.extend(utc);
dayjs.extend(isBetween);

export type AreaCardProps = {
    area: any;
    onStop: () => void;
    onDetails: () => void;
    onEdit: () => void;
    onDelete: () => void;
};

const AreaCard = ({area, onStop, onDetails, onEdit, onDelete}: AreaCardProps) => {
    const {t} = useTranslation();

    const closedMessage: string | null = useMemo(() => {
        const now = dayjs();
        let msg = null;

        if (!Array.isArray(area.AreaClosedSchedule)) return msg;

        area.AreaClosedSchedule?.forEach((schedule: any) => {
            const from = dayjs(schedule.from);
            const to = dayjs(schedule.to);
            if (now.isBetween(from, to, 'minute', '()')) {
                msg = `${from.utc().format('DD/MM/YYYY HH:mm')} - ${to.utc().format('DD/MM/YYYY HH:mm')}`;
                return;
            }
        });
        return msg;
    }, [area]);

    return (
        <AreaCardStyled>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2}>
                    <Box flex={2}>
                        <img
                            src={area.AreaPhoto && area.AreaPhoto.length > 0 ? area.AreaPhoto[0].photoUrl : noImage}
                            style={{
                                width: '100%',
                                height: '160px',
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                            alt=""
                        />
                    </Box>
                    <Stack flex={3}>
                        <Stack direction="row" spacing={1} justifyContent="end">
                            <IconButton aria-label="delete" size="small" color="primary" onClick={onDelete}>
                                <Delete />
                            </IconButton>
                            <IconButton aria-label="edit" size="small" color="primary" onClick={onEdit}>
                                <Edit />
                            </IconButton>
                        </Stack>
                        <Typography variant="body1">{area.name}</Typography>
                        <Typography variant="caption">{area.description}</Typography>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Button fullWidth variant="outlined" size="small" onClick={onStop}>
                        {t('stop')}
                    </Button>
                    <Button fullWidth variant="contained" size="small" onClick={onDetails}>
                        {t('details')}
                    </Button>
                </Stack>
            </Stack>
            {closedMessage && (
                <Tooltip
                    data-testid="area-closed-tooltip"
                    title={t('areaIsClosed').replace('{{schedule}}', closedMessage)}
                    arrow
                    sx={{position: 'absolute', top: '8px', left: '8px'}}
                >
                    <Chip color="primary" size="small" icon={<ReportGmailerrorredIcon />} label={t('stopped')} />
                </Tooltip>
            )}
        </AreaCardStyled>
    );
};

export default AreaCard;
