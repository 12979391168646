import {TimePicker, TimePickerProps} from '@mui/x-date-pickers';
import {Dayjs} from 'dayjs';
import styled from 'styled-components';

export const StyledTimePicker = styled(TimePicker)<TimePickerProps<Dayjs>>`
    background-color: #fff;
    border: transparent;
    border-radius: 4px;
    box-shadow: var(--default-boxShadow);
    & .Mui-error {
        background-color: #ffebee;
    }
`;
