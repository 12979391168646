import {Box, Button, FormControl, FormLabel, Select} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';

export const FormLabelStyled = styled(FormLabel)`
    color: #424242;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.25px;

    &.Mui-focused {
        color: var(--custom-secondary);
    }

    & .MuiFormLabel-asterisk {
        color: red;
    }
`;
export const StyledFormControl = styled(FormControl)`
    display: grid;
    gap: 16px;
`;

export const FormLabelDescription = styled(FormLabel)`
    color: #424242;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;

    &.Mui-focused {
        color: #424242;
    }

    & .MuiFormLabel-asterisk {
        color: red;
    }
`;

export const Description = styled.p`
    color: #424242;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin: 0;

    @media (min-width: 600px) {
        font-size: 20px;
        margin: 16px 0;
    }
`;

export const StyledBox = styled(Box)`
    color: red;
`;

export const SelectGrid = styled(Box)`
    display: grid;
    grid-template-columns: 50% auto;
    gap: 8px;
`;

export const StyledContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledErrorBox = styled(Box)`
    display: flex;
    margin-top: 8px;
`;

export const StyledImageContainer = styled(Box)`
    display: grid;
    grid-template-columns: 50% auto;
    gap: 5px;
`;

export const StyledButtonBox = styled(Box)`
    position: relative;
    width: 100%;
`;

export const StyledDeleteButton = styled(Button)`
    position: absolute !important;
    min-width: auto;
    bottom: 10px;
    right: 10px;
    padding: 5px;
`;

export const StyledScheduleCard = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`;

export const FormBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const StyledButton = styled(AppButton).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isHidden',
})<{isHidden?: boolean}>`
    display: ${({isHidden}) => (isHidden ? 'none' : 'flex')};
`;

export const ButtonBox = styled(Box)`
    display: flex;
    gap: 6px;
`;

export const InputBox = styled(Box)`
    display: grid;
    gap: 8px;
    padding: 16px;
    background-color: #f1f1f1;
    border-radius: 4px;
`;

export const StyledSelectControl = styled(FormControl)`
    width: 100%;
`;

export const StyledStack = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const ScheduleBox = styled(Box)`
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 16px;

    @media (max-width: 600px) {
        grid-template-columns: auto;
    }
`;

export const StyledDateBox = styled(Box)`
    display: grid;
    gap: 1rem;
    grid-template-columns: 50% auto;

    @media (max-width: 600px) {
        grid-template-columns: auto;
    }
`;

export const Title = styled.p`
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
`;

export const SelectStyled = styled(Select)`
    background-color: #fff;
    width: 100%;

    & .MuiOutlinedInput-notchedOutline {
        border-color: transparent !important;
        box-shadow: var(--default-boxShadow);
    }

    & .Mui-error {
        border: 1px solid red;
        background: #ffebee;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    max-height: 350px;
    object-fit: cover;
`;

export const ScheduleCard = styled.div`
    padding: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const StyledAppButton = styled(AppButton)`
    display: flex;
    justify-content: end;
    padding: 0;
`;

export const Weekday = styled.div`
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-primary);
`;

export const ClosedScheduleCard = styled.div`
    padding: 0;
`;

export const ModalButtons = styled(AppButton)`
    width: 100%;
`;
