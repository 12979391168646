import {useQuery} from '@tanstack/react-query';
import {AppService} from '../../../../services/app.service';

export function useCountAndEvolution(
    startDate: string,
    endDate: string,
    clubId: string,
    fetchingData: boolean,
    areaId?: string
) {
    const appService = new AppService();

    const {isLoading, data = []} = useQuery({
        queryKey: ['getCountAndEvolution', clubId, areaId, startDate, endDate],
        queryFn: async () => {
            const res = await appService.api_getCountAndEvolution(startDate, endDate, clubId, areaId);

            return res.data;
        },
        retry: false,
        enabled: fetchingData,
    });

    return {data, isLoading};
}
