import React, {FC} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StyledDatePicker} from './DatePicker.styles';
import {useTranslation} from 'react-i18next';
import {enUS, ptBR} from '@mui/x-date-pickers/locales';
import {DatePickerProps} from '@mui/x-date-pickers/DatePicker';
import {Dayjs} from 'dayjs';

const MUIDatePicker: FC<DatePickerProps<Dayjs>> = ({...props}) => {
    const {i18n} = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
            <StyledDatePicker format="DD-MM-YYYY" {...props} />
        </LocalizationProvider>
    );
};

export default MUIDatePicker;
