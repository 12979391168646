import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    Alert,
    Checkbox,
    Fab,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {AreaDeleteForm} from '../../../../components/AreaDeleteForm/AreaDeleteForm';
import {Add, Tune} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {AppService} from '../../../../services/app.service';
import {Category} from '../../../../shared/types/Category.type';
import {useTranslation} from 'react-i18next';
import {useCommon} from '../../../../context/CommonContext';
import {StyledStack, Title} from './ClubAreas.styles';
import {Layout} from '../../../../components/Common/Layout/Layout';
import {AppContext} from '../../../../context/AppContext';
import {Select} from '../../../../components/Common/Select/Select';
import AreaCard from '../../../../components/AreaCard/AreaCard';
import {useClubById} from '../../../../hooks/useClubById';

const AreaManagement = () => {
    //AppContext
    const appContext = useContext(AppContext);
    const clubId = appContext.club?.id;
    const {t} = useTranslation();
    const {setModal} = useCommon();
    const navigate = useNavigate();

    const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
    const [selectedSports, setSelectedSports] = useState<any>([]);
    const {data: club} = useClubById('private', clubId!);

    const handleSportChange = (event: SelectChangeEvent<typeof selectedSports>) => {
        const {value} = event.target;
        setSelectedSports(typeof value === 'string' ? value.split(',') : value);
    };

    const filteredAreas = useMemo(() => {
        if (selectedSports.length === 0) return club.areas;
        const arr: any[] = [];
        club.areas.forEach((area: any) => {
            const isInSelected = area.AreaCategory.map((a: any) => a.category).some((value: string) =>
                selectedSports.includes(value)
            );
            if (isInSelected) arr.push(area);
        });
        return arr;
    }, [club.areas, selectedSports]);

    return (
        <Layout headerType="menu" maxWidth="sm">
            <Stack spacing={3} mb={10} px={1} mt={2}>
                <Title>{t('areasOfClub')}</Title>
                <AppButton variant="contained" startIcon={<Tune />} onClick={() => setFiltersOpen((prev) => !prev)}>
                    {t('filter')}
                </AppButton>
                {filtersOpen && (
                    <FormControl sx={{width: '100%'}}>
                        <InputLabel id="select-sports-label">{t('clubAdmin.bookings.selectCategory')}</InputLabel>
                        <Select
                            variant="outlined"
                            multiple
                            labelId="select-sports-label"
                            label={t('clubAdmin.bookings.selectCategory')}
                            value={selectedSports}
                            onChange={handleSportChange}
                            renderValue={(selected: any) => selected.join(', ')}
                        >
                            {club.categories?.map((sport: any) => (
                                <MenuItem key={sport} value={sport}>
                                    <Checkbox checked={selectedSports.indexOf(sport) > -1} />
                                    <ListItemText primary={sport} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <StyledStack>
                    {filteredAreas?.map((a: any) => (
                        <AreaCard
                            key={a.id}
                            area={a}
                            onStop={() => {}}
                            onDetails={() => {}}
                            onEdit={() => navigate(`/club-admin/club-areas/${a.id}`)}
                            onDelete={() =>
                                setModal({
                                    open: true,
                                    children: <AreaDeleteForm area={a} onDelete={() => {}} />,
                                    title: `${t('areaDelete')} \"${a.name}\"`,
                                })
                            }
                        />
                    ))}
                    {club.areas?.length < 1 && (
                        <Alert variant="filled" severity="info">
                            {t('noAreas')}
                        </Alert>
                    )}
                </StyledStack>
            </Stack>
            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                sx={{position: 'fixed', bottom: 16, right: 16}}
                onClick={() => navigate('/club-admin/club-areas/')}
            >
                <Add sx={{mr: 1}} />
                {t('newArea')}
            </Fab>
        </Layout>
    );
};

export default AreaManagement;
