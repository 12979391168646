import {useMutation} from '@tanstack/react-query';
import {AppService} from '../services/app.service';
import {ResetPassword} from '../shared/types/ResetPassword.type';

export function useResetPassword(role: string) {
    const appService = new AppService();

    const {mutate, isError} = useMutation({
        mutationKey: ['resetPassword'],
        mutationFn: async (userData: ResetPassword) => {
            const res = await appService.api_resetPassword(role, userData);
            return res;
        },
        retry: false,
    });

    return {mutate, isError};
}
