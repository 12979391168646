import {useQuery} from '@tanstack/react-query';
import {AppService} from '../services/app.service';

export function useBookingsByUser(startDate: string, endDate: string, fetchData: boolean) {
    const appService = new AppService();

    const {isLoading, data = []} = useQuery({
        queryKey: ['getBookingsByUser', startDate, endDate],
        queryFn: async () => {
            const res = await appService.api_getBookingsByUser(startDate, endDate);

            return res.data;
        },
        retry: false,
        enabled: fetchData,
    });

    return {data, isLoading};
}
