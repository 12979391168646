import translation_en from './en/common.json';
import translation_pt from './pt/common.json';

const resources = {
    pt: {
        translation: translation_pt,
    },
    en: {
        translation: translation_en,
    },
} as const;

export default resources;
