import {Alert, Box, FormControl, Typography} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../components/Common/Buttons/AppButton';

export const MobileFilter = styled(Box)`
    display: none;
    flex-direction: row;
    gap: 0.75rem;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        display: flex;
    }
`;

export const DesktopFilter = styled(Box).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isFilterOpen',
})<{isFilterOpen?: boolean}>`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    gap: 1rem;

    @media (max-width: 600px) {
        display: ${({isFilterOpen}) => (isFilterOpen ? 'flex' : 'none')};
    }
`;

export const FilterButton = styled(AppButton).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isFilterOpen',
})<{isFilterOpen?: boolean}>`
    width: ${({isFilterOpen}) => (isFilterOpen ? '100%' : '119px')};
    min-height: 56px;
`;

export const SelectGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 20% 20% 15% 15% auto auto;
    gap: 8px;

    @media (max-width: 900px) {
        grid-template-columns: auto auto auto;
        gap: 1rem;
    }
    @media (max-width: 600px) {
        grid-template-columns: auto;
        gap: 1rem;
    }
`;

export const StyledSelectControl = styled(FormControl)`
    width: 100%;
`;

export const StyledAlert = styled(Alert)`
    width: 100%;
`;

export const StyledContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const StyledError = styled(Typography)`
    color: #d32f2f;
    font-size: 12px !important;
    margin: 3px 14px 0 14px !important;
`;
