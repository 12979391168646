import {Paper} from '@mui/material';
import styled, {css} from 'styled-components';

export const DataTitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--black-054);
    margin: 0;
    display: flex;
    align-items: center;
`;

export const StyledPaper = styled(Paper)`
    padding: 8px 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const TotalReservations = styled.h1`
    font-size: 48px;
    color: var(--black-054);
    margin: 0;
    margin-bottom: 20px;
`;

export const PercentageGrow = styled.div.withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isPositive',
})<{isPositive?: boolean}>`
    background-color: ${({isPositive}) => (isPositive ? '#E8F5E9' : '#FFEBEE')};
    color: var(--black-087);
    padding: 4px 8px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    width: fit-content;
`;

export const ChartContainer = styled.div`
    padding: 0;
`;

export const TitleContainer = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
`;

export const DashboardItemChildren = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
`;

const commonStyles = css`
    font-weight: 400;
    margin: 0;
    color: var(--black-054);
`;

export const GridItemResult = styled.h3`
    font-size: 36px;
    line-height: 44px;
    min-height: 55px;
    ${commonStyles}
`;

export const TopUserResult = styled.h3`
    font-size: 20px;
    line-height: 28px;
    min-height: 28px;
    display: flex;
    align-items: center;
    ${commonStyles}
`;

const commonResultContainer = css`
    display: flex;
    gap: 8px;
    color: var(--black-054);
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
`;

export const GridItemResultContainer = styled.div`
    align-items: baseline;
    ${commonResultContainer}
`;

export const TopUserResultContainer = styled.div`
    align-items: center;
    flex-direction: column;
    ${commonResultContainer}
`;

export const DashBoardItemFooter = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const ItemResultLegend = styled.p`
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: var(--black-038);
    margin: 0;
`;
