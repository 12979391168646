import {Alert, Box, DialogActions, DialogContent, FormControl, MenuItem, Typography} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../../components/Common/Buttons/AppButton';

export const ButtonCalendarView = styled(AppButton)`
    width: fit-content;
    max-height: 58px !important;
    height: 100%;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const StyledAlert = styled(Alert)`
    width: 100%;
`;

export const StyledContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const StyledError = styled(Typography)`
    color: #d32f2f;
    font-size: 12px !important;
    margin: 3px 14px 0 14px !important;
`;

export const TimeBox = styled(Box)`
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

export const StyledSearchBox = styled(Box)`
    width: 343px !important;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const StyledSelectBox = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const StyledMenuItem = styled(MenuItem)`
    display: flex;
    gap: 10px;
    align-items: center;
`;

export const StyledDialogActions = styled(DialogActions)`
    justify-content: space-between !important;
`;
