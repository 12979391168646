//React imports
import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
//External dependencies
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {
    Alert,
    AlertTitle,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
} from '@mui/material';
//Internal dependencies
import {AppButton} from '../../../../components/Common/Buttons/AppButton';
import {Asterisk, CountryPhoneCode, InputBox, Label, PaymentMethodContainer, PhoneBox} from './BookingForm.styles';
import {
    CreditCardBox,
    PaymentMethodLabel,
    StyledCard,
    StyledCardContent,
    StyledCardLabel,
    StyledFormControlLabel,
    TermsAndConditionsLabel,
    TermsAndConditionsLink,
} from '../createBooking/Booking.styles';
import {BookingFormTypes} from './BookingForm.types';
import {formatDate} from '../../../../utils/dates';
import {getTimeUnit} from '../../../../utils/getTimeUnit';
import {useUserContext} from '../../../edit-account/contexts/UserContext';
import useClubContext from '../../../club/context/ClubContext';
import {useAuth} from '../../../../hooks/useAuth';
import {useClub} from '../../../club/hooks/useClub';
import {PaymentMethod} from '../../../../models/clubDetails.model';
import MBWAY from '../../../../assets/icons/MBWay_logo.svg';
import VISA from '../../../../assets/icons/Visa_logo.svg';
import MASTERCARD from '../../../../assets/icons/Mastercard_logo.svg';
import {useFeatureToggles} from '../../../../hooks/useFeatureToggles';
import {ReservationTypeEnum} from '../../../../shared/enums/ReservationType.enum';
import {useCreateReservation} from '../../../../hooks/useCreateReservation';
import {useCreditCardPayment} from '../../../../hooks/useCreditCardPayment';
import {useCommon} from '../../../../context/CommonContext';
import {MessageEventType} from '../../../../shared/enums/MessageEventType.enum';
import {Input} from '../../../../components/Common/Input/TextField/Input';
import {useMBWayPayment} from '../../../../hooks/useMBWayPayment';
import {PaymentMethodType} from '../../../../shared/enums/PaymentMethodType.enum';
import {PaymentStatusType} from '../../../../shared/enums/PaymentStatusType.enum';

export const BookingForm: React.FC<BookingFormTypes> = ({
    areaName,
    areaId,
    price,
    privacy,
    category,
    date,
    time,
    timeUnit,
    clubId,
}) => {
    const {t} = useTranslation();
    const {isAuthenticated, role} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const isReady = useRef(false);
    const {user} = useUserContext();
    const {club} = useClubContext();
    const {
        actions: {fetch},
    } = useClub();

    const {
        actions: {fetchDomainFeatureToggles, hasDomainFeatureToggle},
    } = useFeatureToggles();

    useEffect(() => {
        if (isReady.current) return;
        isReady.current = true;
        if (!!clubId) {
            fetch(clubId);
            fetchDomainFeatureToggles();
        }
    }, []);
    const {setSnackbar} = useCommon();

    const {mutate: createReservation, isPending} = useCreateReservation('public');
    const {mutate: creditCardPayment, isPending: isPendingCreditCard} = useCreditCardPayment();
    const {mutate: mbwayPayment, isPending: isPendingMBWay} = useMBWayPayment();

    const [payment, setPayment] = useState(searchParams.get('payment'));
    const [terms, setTerms] = useState(searchParams.get('terms') ? true : false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [iFrameUrl, setIFrameUrl] = useState<string>('');
    const reservationIdRef = useRef('');
    const [mobilePhone, setMobilePhone] = useState(searchParams.get('mobile'));
    const handleMobilePhoneChange = (newValue: any) => {
        setMobilePhone(newValue.target.value);
    };

    const from = formatDate(dayjs.unix(Number(time)).utc());
    const [hoursToAdd, minutesToAdd] = dayjs.unix(Number(timeUnit)).utc().format('HH:mm').split(':');
    const to = formatDate(
        dayjs.unix(Number(time)).utc().add(Number(hoursToAdd), 'hour').add(Number(minutesToAdd), 'minute')
    );
    const day = formatDate(dayjs.unix(Number(searchParams.get('date'))));

    const handleCreateBooking = () => {
        const selectedPaymentMethod = club?.paymentMethods.find((pm: PaymentMethod) => pm.name === payment);
        const paymentMethodId = selectedPaymentMethod ? selectedPaymentMethod.id : '';
        const booking = {
            name: user?.name,
            email: user?.email,
            category: category.toUpperCase(),
            day,
            from,
            to,
            price: Number(price),
            timeUnit: getTimeUnit(dayjs.unix(Number(timeUnit)).utc().format('HH:mm')),
            areaId,
            reservationType: ReservationTypeEnum.PRIVATE,
            payment: payment,
            paymentMethodId: paymentMethodId ? paymentMethodId : null,
        };

        const return_url = `${location.pathname}${location.search}&terms=${terms}&payment=${payment}&mobile=${mobilePhone}`;

        if (isAuthenticated) {
            createReservation(booking, {
                onSuccess: (data) => {
                    if (data.status !== 201) throw new Error(data.status);
                    const newReservationId = data.data.id;
                    reservationIdRef.current = newReservationId;
                    if (booking.payment?.toLowerCase() === PaymentMethodType.CREDIT_CARD && data.data.id) {
                        creditCardPayment(data.data.id, {
                            onSuccess: (data) => {
                                setIsVisible(true);
                                setIFrameUrl(data.data.paymentUrl);
                            },
                        });
                    } else if (booking.payment?.toLowerCase() === PaymentMethodType.MBWAY && data.data.id) {
                        const mbwayPaymentData = {
                            reservationId: data.data.id,
                            mobilePhone: mobilePhone,
                        };

                        mbwayPayment(mbwayPaymentData, {
                            onSuccess: (data) => {
                                if (data.status !== 200) throw new Error(data.status);
                                if (data.data.status && data.data.status === PaymentStatusType.INITIALIZED) {
                                    navigate('/booking/mbway', {state: {...mbwayPaymentData}});
                                } else {
                                    throw new Error(data.data.status);
                                }
                            },
                            onError: (error: any) => {
                                setSnackbar({
                                    open: true,
                                    title: t(`mbway.status.title.${error.message.toLowerCase()}`),
                                    setOpen: () => {
                                        setSnackbar({open: false, children: null, setOpen: () => {}});
                                    },
                                    severity: 'error',
                                    children: t(`mbway.status.${error.message.toLowerCase()}`),
                                });
                            },
                        });
                    } else {
                        navigate(`/booking-details/${data.data.id}`);
                        setSnackbar({
                            open: true,
                            title: t(`clubAdmin.bookings.success.title`, {
                                area: areaName,
                            }),
                            setOpen: () => {
                                setSnackbar({open: false, children: null, setOpen: () => {}});
                            },
                            severity: 'success',
                            children: t(`clubAdmin.bookings.success.${data.status}`, {
                                area: areaName,
                                clientName: user?.name,
                                day: dayjs(day).format('DD-MM-YYYY'),
                                from: dayjs(from).utc().format('HH:mm'),
                                to: dayjs(to).utc().format('HH:mm'),
                                email: user?.email,
                            }),
                        });
                    }
                },
                onError: (error: any) => {
                    setSnackbar({
                        open: true,
                        title: t('booking.error.title'),
                        setOpen: () => {
                            setSnackbar({open: false, children: null, setOpen: () => {}});
                        },
                        severity: 'error',
                        children: t(`booking.error.${error.message}`),
                    });
                },
            });
        } else {
            navigate(`/checkout/booking?return_url=${encodeURIComponent(return_url)}`, {
                state: {...booking},
            });
        }
    };

    const handleEventListener = (e: MessageEvent) => {
        if (e.data === MessageEventType.ERROR || e.data === MessageEventType.CANCELED) {
            setIsVisible(false);
            setSnackbar({
                open: true,
                title: t('booking.error.title'),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'error',
                children: t(`booking.error.${e.data}`),
            });
        } else if (e.data === MessageEventType.SUCCESS) {
            navigate(`/booking-details/${reservationIdRef.current}`);
            setSnackbar({
                open: true,
                title: t(`clubAdmin.bookings.success.title`, {
                    area: areaName,
                }),
                setOpen: () => {
                    setSnackbar({open: false, children: null, setOpen: () => {}});
                },
                severity: 'success',
                children: t(`clubAdmin.bookings.success.201`, {
                    area: areaName,
                    clientName: user?.name,
                    day: dayjs(day).format('DD-MM-YYYY'),
                    from: dayjs(from).utc().format('HH:mm'),
                    to: dayjs(to).utc().format('HH:mm'),
                    email: user?.email,
                }),
            });
        } else {
            return null;
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleEventListener);

        return () => {
            window.removeEventListener('message', handleEventListener);
        };
    }, []);

    return (
        <>
            {privacy !== 'PUBLIC' && (
                <StyledCard>
                    <StyledCardContent>
                        <StyledCardLabel>
                            {t('booking.label.paymentMethods')}
                            <Asterisk>*</Asterisk>
                        </StyledCardLabel>
                        {club?.paymentMethods && club.paymentMethods.length > 0 ? (
                            <FormControl>
                                <RadioGroup aria-labelledby="payment-group-label" name="payment-group">
                                    {club.paymentMethods.map(
                                        (pm: PaymentMethod) =>
                                            hasDomainFeatureToggle(pm.name) && (
                                                <PaymentMethodContainer key={pm.name}>
                                                    <FormControlLabel
                                                        name="payment"
                                                        value={pm.name}
                                                        control={
                                                            <Radio
                                                                name="payment"
                                                                checked={payment?.includes(pm.name)}
                                                            />
                                                        }
                                                        label={
                                                            <PaymentMethodLabel>
                                                                {t(`club.paymentMethods.${pm.name.toLowerCase()}`)}
                                                                {pm.name === 'MBWAY' && (
                                                                    <img src={MBWAY} alt="MBWay logo" />
                                                                )}
                                                                {pm.name === 'CREDIT_CARD' && (
                                                                    <CreditCardBox>
                                                                        <img src={VISA} alt="Visa logo" />
                                                                        <img src={MASTERCARD} alt="Mastercard logo" />
                                                                    </CreditCardBox>
                                                                )}
                                                            </PaymentMethodLabel>
                                                        }
                                                        onChange={() => setPayment(pm.name)}
                                                    />
                                                    {payment === 'MBWAY' && pm.name === 'MBWAY' && (
                                                        <InputBox>
                                                            <Label style={{fontWeight: 'bold'}}>
                                                                {t('clubAdmin.bookings.inputLabel.mobilePhone')}
                                                            </Label>
                                                            <PhoneBox>
                                                                <CountryPhoneCode>351</CountryPhoneCode>
                                                                <Input
                                                                    name="mobilePhone"
                                                                    placeholder={t(
                                                                        'clubAdmin.bookings.inputPlaceholder.mobilePhone'
                                                                    )}
                                                                    type="text"
                                                                    value={mobilePhone}
                                                                    inputProps={{maxLength: 11}}
                                                                    onChange={(e) => {
                                                                        const newValue = e.target.value
                                                                            .replace(/\D/g, '')
                                                                            .replace(
                                                                                /(\d{2})(\d{3})(\d{4})/,
                                                                                '$1 $2 $3'
                                                                            );
                                                                        handleMobilePhoneChange({
                                                                            target: {
                                                                                name: 'mobilePhone',
                                                                                value: newValue,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                                <div></div>
                                                            </PhoneBox>
                                                        </InputBox>
                                                    )}

                                                    {payment === 'CREDIT_CARD' &&
                                                        pm.name === 'CREDIT_CARD' &&
                                                        isAuthenticated &&
                                                        isVisible && (
                                                            <iframe
                                                                id="ifThenPay"
                                                                src={iFrameUrl}
                                                                title="Payment Form"
                                                                width="100%"
                                                                height="490px"
                                                            ></iframe>
                                                        )}

                                                    {payment === 'BANK_TRANSFER' && pm.name === 'BANK_TRANSFER' && (
                                                        <Alert variant="filled" severity="info">
                                                            {t('booking.label.importantNote')}
                                                        </Alert>
                                                    )}
                                                </PaymentMethodContainer>
                                            )
                                    )}
                                </RadioGroup>
                            </FormControl>
                        ) : (
                            <Alert severity="info">
                                <AlertTitle>{t('booking.noPaymentMethodsTitle')}</AlertTitle>
                                {t('booking.noPaymentMethodsInfo')}
                            </Alert>
                        )}
                    </StyledCardContent>
                </StyledCard>
            )}
            <StyledCard>
                <StyledCardContent>
                    <StyledCardLabel>
                        {t('termsAndConditions')}
                        <Asterisk>*</Asterisk>
                    </StyledCardLabel>
                    <FormGroup>
                        <StyledFormControlLabel
                            control={<Checkbox name="terms" checked={terms} onChange={() => setTerms(!terms)} />}
                            label={
                                <TermsAndConditionsLabel>
                                    {t('booking.label.acceptance')}{' '}
                                    <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                        <TermsAndConditionsLink>
                                            {t('booking.label.termsAndConditions')}
                                        </TermsAndConditionsLink>
                                    </Link>{' '}
                                    {t('booking.label.sportsFacility')}
                                </TermsAndConditionsLabel>
                            }
                        />
                    </FormGroup>
                </StyledCardContent>
            </StyledCard>
            {!isVisible && (
                <AppButton
                    variant="contained"
                    type="submit"
                    onClick={handleCreateBooking}
                    disabled={
                        isPending ||
                        isPendingCreditCard ||
                        isPendingMBWay ||
                        (privacy !== 'PUBLIC' && payment === null) ||
                        terms === false
                    }
                >
                    {t('booking.button.book')}
                </AppButton>
            )}
        </>
    );
};
