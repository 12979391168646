import {Box} from '@mui/material';
import styled, {css} from 'styled-components';

const commonStyles = css`
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CountdownBox = styled(Box)`
    position: relative;

    ${commonStyles};
`;

export const LabelBox = styled(Box)`
    bottom: 0;
    right: 0;
    position: absolute;

    ${commonStyles};
`;

export const CountdownText = styled.p`
    color: var(--color-primary);
    font-size: 14px;
    margin: 0;
`;
