import {Grid} from '@mui/material';
import React, {FC, PropsWithChildren} from 'react';
import {StyledPaper, DataTitle, DashboardItemChildren} from './Dashboard.styles';

type DashBoardItemProps = {
    xs: number;
    sm: number;
    md?: number;
    title: string;
};

export const DashBoardItem: FC<PropsWithChildren<DashBoardItemProps>> = ({xs, sm, md, title, children}) => {
    return (
        <Grid item xs={xs} sm={sm} md={md}>
            <StyledPaper>
                <DataTitle>{title}</DataTitle>
                <DashboardItemChildren>{children}</DashboardItemChildren>
            </StyledPaper>
        </Grid>
    );
};
