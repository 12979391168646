export const barBackgroundPlugin = {
    id: 'barBackgroundPlugin',
    beforeDatasetsDraw: (chart: any) => {
        const ctx = chart.ctx;
        const meta = chart.getDatasetMeta(0);
        const bars = meta.data;
        ctx.save();
        bars.forEach((bar: any) => {
            const x = bar.getProps(['x']).x;
            const y = bar.getProps(['y']).y;
            const width = bar.width;
            const height = bar.height;
            const base = bar.getProps(['base']).base;
            ctx.fillStyle = '#d9d9d9';
            if (chart.options.indexAxis === 'y') {
                ctx.fillRect(base, y - height / 2, chart.chartArea.right - base, height);
            } else {
                ctx.fillRect(x - width / 2, base, width, chart.chartArea.bottom - base);
            }
        });
        ctx.restore();
    },
};
