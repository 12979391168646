import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Alert, Fab, Stack, Typography} from '@mui/material';
import {Add as AddIcon} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import BookingCard from '../../components/BookingCard/BookingCard';
import {BookingCancelModal} from '../../components/BookingCancelModal/BookingCancelModal';
import {Layout} from '../../components/Common/Layout/Layout';
import MUIDatePicker from '../../components/DatePicker/DatePicker';
import {useBookingsByUser} from '../../hooks/useBookingsByUser';
import {useFormik} from 'formik';
import {Spinner} from '../../components/Common/Spinner/Spinner';
import {useCommon} from '../../context/CommonContext';
import {Booking} from '../../models/booking.model';
import {validationBookingsByUserSchema} from '../../models/validationBookingsByUserSchema.model';

const Bookings = () => {
    const {t} = useTranslation();
    const {setModal} = useCommon();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            startDate: dayjs().startOf('month'),
            endDate: dayjs().endOf('month'),
        },
        validationSchema: validationBookingsByUserSchema(t),
        onSubmit: () => {},
    });

    const {data: bookings, isLoading} = useBookingsByUser(
        `${dayjs(formik.values.startDate).format('YYYY-MM-DD')}T00:00:00.000Z`,
        `${dayjs(formik.values.endDate).format('YYYY-MM-DD')}T23:59:00.000Z`,
        true
    );

    const handleCardCancel = (booking: Booking) => {
        setModal({
            open: true,
            children: <BookingCancelModal booking={booking} />,
            title: t('cancelBookingReservation', {booking: booking.shareId}),
        });
    };

    return (
        <>
            <Layout headerType="menu" maxWidth="sm">
                <Stack spacing={2} p={1}>
                    <Typography variant="h5" textAlign={'center'}>
                        {t('bookings').toLocaleUpperCase()}
                    </Typography>
                    <form style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                        <MUIDatePicker
                            sx={{width: '100%', backgroundColor: '#fff'}}
                            label={t('search.labels.startDate')}
                            value={formik.values.startDate}
                            slotProps={{
                                textField: {
                                    name: 'startDate',
                                    error: formik.errors.startDate as boolean | undefined,
                                    helperText: formik.errors.startDate as string,
                                },
                            }}
                            onChange={(newValue) => formik.handleChange({target: {name: 'startDate', value: newValue}})}
                        />
                        <MUIDatePicker
                            sx={{width: '100%', backgroundColor: '#fff'}}
                            label={t('search.labels.endDate')}
                            value={formik.values.endDate}
                            slotProps={{
                                textField: {
                                    name: 'endDate',
                                    error: formik.errors.endDate as boolean | undefined,
                                    helperText: formik.errors.endDate as string,
                                },
                            }}
                            onChange={(newValue) => formik.handleChange({target: {name: 'endDate', value: newValue}})}
                        />
                    </form>
                    {bookings.length === 0 ? (
                        <Alert variant="filled" severity="info">
                            {t('noBookings')}
                        </Alert>
                    ) : isLoading ? (
                        <Spinner />
                    ) : (
                        bookings?.map((booking: any) => (
                            <BookingCard
                                key={booking.id}
                                booking={{...booking, name: booking.area.name}}
                                onClick={() => navigate(`/booking-details/${booking.id}`)}
                                onCancel={() => handleCardCancel(booking)}
                                onContact={() => navigate(`/club/${booking.club.id}#contacts`)}
                            />
                        ))
                    )}
                </Stack>
            </Layout>
            <Fab
                variant="extended"
                color="primary"
                aria-label="add"
                sx={{position: 'fixed', bottom: 16, right: 16}}
                onClick={() => navigate('/search')}
            >
                <AddIcon sx={{mr: 1}} />
                {t('newBooking')}
            </Fab>
        </>
    );
};

export default Bookings;
