import React, {useContext, useEffect, useState} from 'react';
import {Layout} from '../../../../components/Common/Layout/Layout';
import {
    ChartContainer,
    DashBoardItemFooter,
    GridItemResult,
    GridItemResultContainer,
    ItemResultLegend,
    PercentageGrow,
    TopUserResult,
    TopUserResultContainer,
} from './Dashboard.styles';
import {Alert, Grid, Tooltip} from '@mui/material';
import {
    reservationByAreaOptions,
    reservationByDaysOptions,
    reservationBySportOptions,
    reservationByTimeDayOptions,
    reservationByTypeOptions,
} from './DashboardOptions';
import {
    doughnutToPie,
    mapByAreaData,
    mapByDaysData,
    mapByHourData,
    mapBySportsData,
    mapPaymentsByMethod,
    mapReservationsByType,
} from './DashboardMappers';
import {DashBoardItem} from './DashBoardItem';
import {CalendarIcon, ClockIcon} from '@mui/x-date-pickers';
import {Chart as ChartJS, ArcElement, Legend, Title, BarElement, LinearScale, CategoryScale} from 'chart.js';
import {Bar, Line, Pie, Doughnut} from 'react-chartjs-2';
import {useReservationsByArea} from '../hooks/useReservationByArea';
import {useReservationsBySport} from '../hooks/useReservationBySport';
import {useReservationsByDays} from '../hooks/useReservationByDays';
import {usePaymentInfo} from '../hooks/usePaymentInfo';
import {useCountAndEvolution} from '../hooks/useCountAndEvolution';
import {useReservationsByType} from '../hooks/useReservationByType';
import {usePaymentsByMethod} from '../hooks/usePaymentsByMethod';
import {useReservationsByHour} from '../hooks/useReservationByHour';
import {useTopUsers} from '../hooks/useTopUsers';
import {useCanceledPaymentsByField} from '../hooks/useCanceledPaymentsByField';
import {AppContext} from '../../../../context/AppContext';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PersonIcon from '@mui/icons-material/Person';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {useTranslation} from 'react-i18next';
import {SelectedClubFilter} from '../../filters/selected-club-filter/SelectedClubFilter';
import {Form, Formik} from 'formik';
import DesktopFilter from '../../filters/desktop-filter/DesktopFilter';
import dayjs from 'dayjs';
import {barBackgroundPlugin} from '../utils/BarBackgroundPlugin';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Legend);

export const Dashboard = () => {
    const {t} = useTranslation();
    const appContext = useContext(AppContext);
    const appClub = appContext.club;
    const [fetchingData, setFetchingData] = useState<boolean>(appClub === null || appClub === undefined ? false : true);
    const [isListView, setIsListView] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
    const [initialValues, setInitialValues] = useState<any>({
        selectedArea: 'all',
        dateRangePicker: `${dayjs().startOf('month').format('YYYY-MM-DD')} - ${dayjs()
            .endOf('month')
            .format('YYYY-MM-DD')}`,
    });

    useEffect(() => {
        setInitialValues({
            ...initialValues,
            selectedArea: 'all',
        });
    }, [appClub]);

    const dateRange = initialValues.dateRangePicker.split(' - ');
    const startDate = dateRange[0];
    const endDate = dateRange[1];

    const {data: reservationByArea} = useReservationsByArea(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: reservationBySport} = useReservationsBySport(
        appClub ? appClub.id : '',
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: reservationByType} = useReservationsByType(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: countAndEvolution} = useCountAndEvolution(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: paymentInfo} = usePaymentInfo(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: reservationByDays} = useReservationsByDays(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: paymentsByMethod} = usePaymentsByMethod(
        appClub ? appClub.id : '',
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: reservationByHour} = useReservationsByHour(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: topUsers} = useTopUsers(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );
    const {data: canceledPaymentsByField} = useCanceledPaymentsByField(
        `${startDate}T00:00:00.000Z`,
        `${endDate}T22:59:59.999Z`,
        appClub ? appClub.id : '',
        fetchingData,
        initialValues.selectedArea !== 'all' ? initialValues.selectedArea : ''
    );

    return (
        <Layout headerType="menu" maxWidth={false}>
            <SelectedClubFilter />
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={''}
                onSubmit={async (values: any) => {
                    setInitialValues(values);
                    setFetchingData(true);
                }}
            >
                {(props) => (
                    <Form onSubmit={props.handleSubmit} style={{width: '100%'}}>
                        <DesktopFilter
                            isFilterOpen={isFilterOpen}
                            values={props.values}
                            errors={props.errors}
                            touched={props.touched}
                            handleChange={props.handleChange}
                            areas={appClub ? appClub.areas : []}
                            sports={appClub ? appClub.categories : []}
                            isListView={isListView}
                            setIsListView={setIsListView}
                            setFieldValue={props.setFieldValue}
                        />
                    </Form>
                )}
            </Formik>
            {fetchingData ? (
                <Grid container spacing={2} marginTop={2} marginBottom={3}>
                    <DashBoardItem xs={12} sm={4} title={t('dashboard.totalReservations')}>
                        <GridItemResultContainer>
                            <CalendarIcon color="inherit" />
                            <GridItemResult>{countAndEvolution.total}</GridItemResult>
                        </GridItemResultContainer>
                        <DashBoardItemFooter>
                            <PercentageGrow isPositive={countAndEvolution.totalEvolution > 0}>
                                {countAndEvolution.totalEvolution > 0 ? '+' : ''}
                                {Math.round(countAndEvolution.totalEvolution * 100) / 100}%
                            </PercentageGrow>
                            <ItemResultLegend>{t('dashboard.note')}</ItemResultLegend>
                        </DashBoardItemFooter>
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={4} title={t('dashboard.totalHours')}>
                        <GridItemResultContainer>
                            <ClockIcon color="inherit" />
                            <GridItemResult>{countAndEvolution.totalHours}</GridItemResult>
                        </GridItemResultContainer>
                        <DashBoardItemFooter>
                            <PercentageGrow isPositive={countAndEvolution.totalHoursEvolution > 0}>
                                {countAndEvolution.totalHoursEvolution > 0 ? '+' : ''}
                                {Math.round(countAndEvolution.totalHoursEvolution * 100) / 100}%
                            </PercentageGrow>
                            <ItemResultLegend>{t('dashboard.note')}</ItemResultLegend>
                        </DashBoardItemFooter>
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={4} title={t('dashboard.timeReservation')}>
                        <GridItemResultContainer>
                            <ClockIcon color="inherit" />
                            <GridItemResult>
                                {Math.round(countAndEvolution.totalHoursPerReservation * 100) / 100}h
                            </GridItemResult>
                        </GridItemResultContainer>
                        <DashBoardItemFooter>
                            <PercentageGrow isPositive={countAndEvolution.totalHoursPerReservationEvolution > 0}>
                                {countAndEvolution.totalHoursPerReservationEvolution > 0 ? '+' : ''}
                                {Math.round(countAndEvolution.totalHoursPerReservationEvolution * 100) / 100}%
                            </PercentageGrow>
                            <ItemResultLegend>{t('dashboard.note')}</ItemResultLegend>
                        </DashBoardItemFooter>
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={6} md={5} title={t('dashboard.reservationByArea')}>
                        {Object.keys(reservationByArea).length > 0 ? (
                            <ChartContainer>
                                <Pie
                                    data={mapByAreaData(reservationByArea, appClub?.areas)}
                                    options={reservationByAreaOptions}
                                />
                            </ChartContainer>
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>

                    <DashBoardItem xs={12} sm={6} md={2} title={t('dashboard.reservationByDay')}>
                        {Object.keys(reservationByDays).length > 0 ? (
                            <ChartContainer>
                                <Bar
                                    id="reservationByDays"
                                    data={mapByDaysData(reservationByDays, 7)}
                                    options={reservationByDaysOptions(reservationByDays)}
                                    plugins={[barBackgroundPlugin]}
                                />
                            </ChartContainer>
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={6} md={5} title={t('dashboard.reservationByTimeAndDay')}>
                        {Object.keys(reservationByHour).length > 0 ? (
                            <Line
                                id="reservationByHours"
                                options={reservationByTimeDayOptions(reservationByHour)}
                                data={mapByHourData(reservationByHour, appClub)}
                            />
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={6} title={t('dashboard.reservationBySport')}>
                        {Object.keys(reservationBySport).length > 0 ? (
                            <ChartContainer>
                                <Doughnut
                                    data={mapBySportsData(reservationBySport)}
                                    options={reservationBySportOptions}
                                />
                            </ChartContainer>
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={12} md={6} title={t('dashboard.reservationByType')}>
                        {Object.keys(reservationByType).length > 0 ? (
                            <Bar
                                id="reservationByType"
                                data={mapReservationsByType(reservationByType, t)}
                                options={reservationByTypeOptions(reservationByType)}
                            />
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={4} title={t('dashboard.totalPayments')}>
                        <GridItemResultContainer>
                            <CalendarIcon color="inherit" />
                            <GridItemResult>{paymentInfo.total}</GridItemResult>
                        </GridItemResultContainer>
                        <DashBoardItemFooter>
                            <PercentageGrow isPositive={paymentInfo.totalEvolution > 0}>
                                {paymentInfo.totalEvolution > 0 ? '+' : ''}
                                {paymentInfo.totalEvolution}%
                            </PercentageGrow>
                            <ItemResultLegend>{t('dashboard.note')}</ItemResultLegend>
                        </DashBoardItemFooter>
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={4} title={t('dashboard.totalPendantPayments')}>
                        <GridItemResultContainer>
                            <HourglassEmptyIcon color="inherit" />
                            <GridItemResult>{paymentInfo.pending}</GridItemResult>
                        </GridItemResultContainer>
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={4} title={t('dashboard.totalCalceledPayments')}>
                        <GridItemResultContainer>
                            <EventBusyIcon color="inherit" />
                            <GridItemResult>{paymentInfo.total}</GridItemResult>
                        </GridItemResultContainer>
                        <DashBoardItemFooter>
                            <PercentageGrow isPositive={paymentInfo.cenceledEvolution > 0}>
                                {paymentInfo.cenceledEvolution > 0 ? '+' : ''}
                                {paymentInfo.canceledEvolution}%
                            </PercentageGrow>
                            <ItemResultLegend>{t('dashboard.note')}</ItemResultLegend>
                        </DashBoardItemFooter>
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={6} title={t('dashboard.paymentsByType')}>
                        {Object.keys(paymentsByMethod).length > 0 ? (
                            <ChartContainer>
                                <Doughnut
                                    data={mapPaymentsByMethod(paymentsByMethod)}
                                    options={reservationBySportOptions}
                                />
                            </ChartContainer>
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={6} title={t('dashboard.paymentCanceledByArea')}>
                        {Object.keys(canceledPaymentsByField).length > 0 ? (
                            <ChartContainer>
                                <Pie
                                    data={doughnutToPie(mapBySportsData(canceledPaymentsByField))}
                                    options={reservationByAreaOptions}
                                />
                            </ChartContainer>
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={6} title={t('dashboard.totalUsers')}>
                        <GridItemResultContainer>
                            <PersonIcon color="inherit" />
                            <GridItemResult>{topUsers.total}</GridItemResult>
                        </GridItemResultContainer>
                        {topUsers && (
                            <DashBoardItemFooter>
                                <PercentageGrow isPositive={topUsers.totalEvolution > 0}>
                                    {topUsers.totalEvolution > 0 ? '+' : ''}
                                    {topUsers.totalEvolution}%
                                </PercentageGrow>
                                <ItemResultLegend>{t('dashboard.note')}</ItemResultLegend>
                            </DashBoardItemFooter>
                        )}
                    </DashBoardItem>
                    <DashBoardItem xs={12} sm={6} title={t('dashboard.topUsers')}>
                        {topUsers.topUsers && topUsers.topUsers.length > 0 ? (
                            topUsers.topUsers.map((user: any, index: number) => (
                                <Tooltip
                                    key={index}
                                    title={`${user.count || 0} reservas`}
                                    placement="right-start"
                                    color="disabled"
                                >
                                    <TopUserResultContainer>
                                        <TopUserResult>
                                            {index === 0 && <EmojiEventsIcon color="inherit" />}
                                            {user.name}
                                        </TopUserResult>
                                    </TopUserResultContainer>
                                </Tooltip>
                            ))
                        ) : (
                            <Alert severity="info" sx={{width: '100%'}}>
                                {t('noData')}
                            </Alert>
                        )}
                    </DashBoardItem>
                </Grid>
            ) : (
                <Alert variant="filled" severity="info" sx={{width: '100%', marginTop: 2}}>
                    {t('clubAdmin.historyData.selectFilterToShowResults')}
                </Alert>
            )}
        </Layout>
    );
};
