import React, {useState} from 'react';
import {Input} from '../Common/Input/TextField/Input';
import Stack from '@mui/material/Stack';
import {AppButton} from '../Common/Buttons/AppButton';
import {AppService} from '../../services/app.service';
import {Dialog, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {isPasswordValid} from '../../utils/validation';
import {useCommon} from '../../context/CommonContext';
import {FormHelperTextStyled} from './ClubAdminFirstLogin.styles';

export type ClubAdminFirstLoginProps = {
    open: boolean;
    onClose: () => void;
    email: string;
    password: string;
};

const formInitialValue = {
    newPassword: '',
    confirmNewPassword: '',
};

export const ClubAdminFirstLogin = ({open, onClose, email, password}: ClubAdminFirstLoginProps) => {
    const {t} = useTranslation();

    const [sendingForm, setSendingForm] = useState(false);
    const [formData, setFormData] = useState(formInitialValue);
    const {setSnackbar} = useCommon();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const validatePassword = (password: string) => {
        return isPasswordValid(password);
    };

    const passwordIsValid = formData.newPassword === '' || validatePassword(formData.newPassword);
    const passwordsMatch = formData.confirmNewPassword === '' || formData.newPassword === formData.confirmNewPassword;

    const handleReset = () => {
        setFormData(formInitialValue);
        onClose();
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const data = {...formData, email, password};

        setSendingForm(true);
        new AppService()
            .api_changeTemporaryPassword('club-admin', data)
            .then((res) => {
                if (res.status !== 200) throw new Error();
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'success',
                    children: t('firstLogin.success'),
                });
                handleReset();
            })
            .catch((error) => {
                console.log(error);
                setSnackbar({
                    open: true,
                    setOpen: () => {
                        setSnackbar({open: false, children: null, setOpen: () => {}});
                    },
                    severity: 'error',
                    children: t('firstLogin.error.onSubmit'),
                });
            })
            .finally(() => setSendingForm(false));
    };

    return (
        <Dialog open={open} onClose={handleReset} maxWidth={'xs'}>
            <form onSubmit={handleSubmit} data-testid="club-admin-first-login-form">
                <Stack spacing={2} p={2}>
                    <Typography variant="h6">{t('firstLogin.title')}</Typography>
                    <Typography variant="body1">{t('firstLogin.description')}</Typography>
                    <label style={{marginTop: 24}} htmlFor="newPassword">
                        {t('firstLogin.newPasswordLabel')}
                    </label>
                    <Input
                        id="newPassword"
                        name="newPassword"
                        label={t('firstLogin.newPassword')}
                        type="password"
                        variant="filled"
                        autoComplete="off"
                        error={!passwordIsValid}
                        required
                        value={formData.newPassword}
                        onChange={handleInputChange}
                    />
                    <FormHelperTextStyled data-testid="newPasswordHelperText">
                        {!passwordIsValid ? t('firstLogin.error.passwordNotValid') : undefined}
                    </FormHelperTextStyled>
                    <label style={{marginTop: 24}} htmlFor="confirmNewPassword">
                        {t('firstLogin.confirmNewPasswordLabel')}
                    </label>
                    <Input
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        label={t('firstLogin.newPassword')}
                        type="password"
                        variant="filled"
                        autoComplete="off"
                        error={!passwordsMatch}
                        required
                        value={formData.confirmNewPassword}
                        onChange={handleInputChange}
                    />
                    <FormHelperTextStyled data-testid="confirmNewPasswordHelperText">
                        {!passwordsMatch ? t('firstLogin.error.passwordNotMatching') : undefined}
                    </FormHelperTextStyled>
                    <AppButton variant="contained" type="submit" disabled={sendingForm} data-testid="submit">
                        {t('firstLogin.submitButton')}
                    </AppButton>
                    <AppButton variant="outlined" onClick={handleReset}>
                        {t('cancel')}
                    </AppButton>
                </Stack>
            </form>
        </Dialog>
    );
};
