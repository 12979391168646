import {Box, DialogContent, FormControl} from '@mui/material';
import styled from 'styled-components';
import {AppButton} from '../../../components/Common/Buttons/AppButton';
import {Input} from '../../../components/Common/Input/TextField/Input';
import {Select} from '../../../components/Common/Select/Select';

export const StyledDesktopFilter = styled(Box).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isFilterOpen',
})<{isFilterOpen?: boolean}>`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: left;
    gap: 8px;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        display: ${({isFilterOpen}) => (isFilterOpen ? 'flex' : 'none')};
        flex-direction: column;
        margin-bottom: 0;
    }
`;

export const SelectGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;

    @media (max-width: 900px) {
        gap: 1rem;
    }
    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const FilterButton = styled(AppButton).withConfig({
    shouldForwardProp: (prop: string): boolean => prop !== 'isFilterOpen',
})<{isFilterOpen?: boolean}>`
    width: ${({isFilterOpen}) => (isFilterOpen ? '100%' : '119px')};
    max-width: 119px;
    min-height: 56px;
    @media (max-width: 600px) {
        max-width: 100%;
    }
`;

export const SearchInput = styled(Input)`
    & .MuiInputBase-input {
        min-width: 343px;
    }
`;

export const StyledMobileFilter = styled(Box)`
    display: none;
    flex-direction: row;
    gap: 0.4rem;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
        display: flex;
    }
`;

export const ButtonView = styled(AppButton)`
    width: 117px;
    max-height: 58px;
    height: 100%;
    @media (max-width: 600px) {
        width: 100%;
        max-height: 42px;
    }
`;
export const ButtonCalendarView = styled(AppButton)`
    width: fit-content;
    max-height: 58px !important;
    height: 100%;
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const SelectClub = styled(Select)`
    background-color: transparent;
    & .MuiOutlinedInput-notchedOutline {
        box-shadow: none;
    }
`;

export const SelectClubBox = styled(Box)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const FilterBox = styled(Box)`
    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        min-width: 278px;
    }
    @media (max-width: 600px) {
        width: 100%;
    }
`;
export const StyledHeader = styled(Box)`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;

    @media (max-width: 600px) {
        display: flex;
        text-align: center;
        flex-direction: column-reverse;
    }
`;
export const StyledDialogContent = styled(DialogContent)`
    padding: 0 !important;
`;
