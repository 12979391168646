import React, {useState} from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import HistoryDataCard from '../HistoryDataCard/HistoryDataCard';
import {useTranslation} from 'react-i18next';
import {HistoryDataModal} from '../HistoryDataModal/HistoryDataModal';
import {StyledAlert, StyledContainer} from '../../HistoryData.styles';
import {HistoryDataType} from '../../types/HistoryData.types';

dayjs.extend(utc);

type HistoryDataListProps = {
    historyData: HistoryDataType[];
};

export const HistoryDataList = ({historyData}: HistoryDataListProps) => {
    const {t} = useTranslation();

    const [bookingModalOpen, setHistoryDataModalOpen] = useState<boolean>(false);
    const [selectedHistoryData, setSelectedHistoryData] = useState<any>(null);

    const handleHistoryDataClick = (booking: any) => {
        setSelectedHistoryData(booking);
        setHistoryDataModalOpen(true);
    };

    if (historyData.length === 0)
        return (
            <StyledAlert variant="filled" severity="info">
                {t('clubAdmin.historyData.noHistory')}
            </StyledAlert>
        );

    return (
        <>
            <StyledContainer>
                {historyData
                    .sort((a: any, b: any) => (dayjs(a.from).isAfter(dayjs(b.from)) ? 1 : -1))
                    .map((history: any) => (
                        <HistoryDataCard
                            key={history.id}
                            history={history}
                            onClick={() => handleHistoryDataClick(history)}
                        />
                    ))}
            </StyledContainer>
            {selectedHistoryData && (
                <HistoryDataModal
                    historyData={selectedHistoryData}
                    open={bookingModalOpen}
                    onClose={() => setHistoryDataModalOpen(false)}
                />
            )}
        </>
    );
};
